import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/react-editor'
import React, { useState, useRef } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  
}));
const postCategory = [
  { value: 'report', label: '분석리포트' },
  { value: 'news', label: '뉴스' },
  { value: 'youtube', label: '유튜브'}
]

function FineEditor() {
  const editorRef = useRef()
  const [title, setTitle] = useState("")
  const [category, setCategory] = useState("report")
  const submitPost = (e) => {
    // // console.log(editorRef.current)
    // editorRef.current.getInstance().exec('bold')
    // e.preventDefault()
    const text = editorRef.current.getInstance().getHTML()
    // console.log(text)
    let result = {
      text: text,
      title: title,
      category: category
    }

    axios.post(process.env.REACT_APP_DB_HOST +'/post', { result })
    .then((res) => {
      // console.log(res)
      alert("포스트 입력 성공.")
      window.location.reload();
  })
  }

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Box sx={{width:'100%'}}>
      <ThemeProvider theme={TABLE_THEME}>
        <Paper elevation={0} variant="outlined">
          {/* <Box sx={{ width:'100%'}}> */}
          <Box sx={{ width:'100%', bgcolor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }} >
            {/* <Stack spacing={0}> */}
              
              
              {/* <Item> */}
              <TextField
                  id="outlined-select"
                  sx={{ width: '30%', m:0}}
                  select
                  label="카테고리"
                  variant='outlined'
                  value={category}
                  onChange={handleChange}
                >
                  {postCategory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-title"
                  variant='outlined'
                  sx={{ width: '70%'}}
                  label="제목"
                  value={title}
                  size="large"
                  onChange={(e) => setTitle(e.target.value)}
                  InputProps={{
                    // readOnly: true,
                  }}
                /> 


              {/* </Item> */}
            {/* </Stack> */}
          </Box>
        </Paper>
      </ThemeProvider>



    <div>
      <Editor 
        minHeight='400px'
        height='700px'
        initialEditType='wysiwyg'
        language='ko-KR'
        initialValue=' '
        theme='dark'
        ref={editorRef}
        // onChange={handleClick}
      />

      <Box sx={{ width:'100%', bgcolor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }} >
        {/* <button onClick={submitPost}> 글쓰기 </button> */}
        <Button variant="contained" onClick={submitPost} size="large">글 저장</Button>
      </Box>
    </div>
   
    </Box>
  );
}
const TABLE_THEME = createTheme({
  typography: {
   "fontFamily": `"MinSans-Medium", "Black Han Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
   "fontSize": 12,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});
export default FineEditor;
