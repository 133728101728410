import * as React from 'react';
import ApexCharts from 'react-apexcharts';
import { useState, useEffect } from 'react';

export default function Payoutratio(funData) {

  const [result, setResult] = useState([])
  const [days, setDays] = useState([])

  useEffect( () => {
    function getRecord() {
        let result = [];
        let days = [];
     
        funData.value.slice(0,5).reverse().forEach( values => {
                                          result.push({x:values.calendardate.toString().substr(0,7), y:(values.payoutratio*100).toFixed(2)})
                                          days.push(values.calendardate.toString().substr(0,7))
        })

        // console.log("Result: ", result)
        // console.log("Days: ", days)
       
        setResult(result)
        setDays(days)
      }
      getRecord()
      }, [funData])

      // console.log("payoutratio : ", result)

        let data = {
    
            series: [{
                name: '배당성향(%)',
                type: 'line',
                data: result
              }],
              options: {
                chart: {
                  height: 350,
                  type: 'line',
                  animations: {
                    enabled: false,
                  },
                  toolbar: {
                      show: false,
                  }
                },
                // stroke: {
                //   width: [0]
                // },
                colors: ['#82CA4A','#9AB3C1' ],
                title: {
                //   text: 'Traffic Sources'
                },
                legend: {
                  position: "top"
                },
                dataLabels: {
                  enabled: true,
                  // enabledOnSeries: [1]
                },
                labels: days,
                xaxis: {
                  // type: 'datetime'
                  categories: days
                },
                yaxis: [{
                  // title: {
                  //   text: 'EPS(금액)',
                  // },
                
                },
                ]
              },
          
      }      

        return (
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="line" width={'100%'} height={'300px'}/>
            </div>
        );
    
}
