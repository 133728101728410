import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ApexCharts from 'react-apexcharts';


export default function DetailStockVolume({chartData}) {
        // const [rowData, setRowData] = useState([]);
        // // console.log(stockcode.value)

        // useEffect( () => {
        //   async function getRecord() {
        //       const response = await axios.get(process.env.REACT_APP_DB_HOST +'/dailychart/' + stockcode.value)
        //       // console.log(response.data);
        //       setRowData(response.data);
        //   }
        //   getRecord()
        // },[])
        const result=[]
        chartData.forEach( values => result.push({ "x": values.date.substr(5,5).replaceAll("-","."), 
                                                    "y": values.volume }))

        const data = {
    
          series: [{
              name: '거래량',
              data: result,
            }],
            options: {
              chart: {
                type: 'bar',
                toolbar: false,
                animations: {
                  enabled: false,
                },
                parentHeightOffset: 0,
                offsetY: -10,
                // sparkline: {
                //     enabled: true
                // },
              },
              colors: ['#f5b942'],
              title: {
                // text: '일일 차트',
                align: 'left'
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                // type: 'datetime',
                tickAmount: 7,
                labels: {
                  show: true,
                  rotate: 0       
                }
              },
              yaxis: {
               show: false
              },
              tooltip: {
                y: {
                  formatter: function(value) {
                    return value.toLocaleString('en-US')
                  }
                }
              }
         
            },
          
      }      

        return (
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="bar" width={'100%'} height={'150px'}/>
            </div>
        );
    
}
