import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import DetailEPS from './detailEPS';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DetailROE from './detailROE';
import DetailDPS from './detailDPS';
import DetailRevenue from './detailRevenue';
import DetailEbit from './detailEbit';
import DetailNetincome from './detailNetincome';
import DetailDebtRatio from './detailDebtRatio';
import DetailCurrentRatio from './detailCurrentRatio';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import DetailOpMargin from './profitQter/detailOpMargin';
import DetailNetMargin from './profitQter/detailNetMargin';
import DetailRoe from './profitQter/detailRoe';
import DetailRoa from './profitQter/detailRoa';
import DetailRoic from './profitQter/detailRoic';
import PerChart from './valuation/perChart';
import PbrChart from './valuation/pbrChart';
import PsrChart from './valuation/psrChart';
import EvebitChart from './valuation/evebitChart';
import EvebitdaChart from './valuation/evebitdaChart';
import TITLE_THEME from '../../customTheme.js';

import Radio from '@mui/material/Radio';

function Valuation() {

//   const toApp = useCallback(
//     text => {
//         onSelect(text)
//     }, [onSelect]
//   )

  const [funData, setFunData] = useState([]);
//   const [roxData, setRoxData] = useState([]);
  const { stockcode } = useParams()

  useEffect( () => {
    async function getRecord() {

    if (typeof stockcode !== 'undefined') {
      const res = await axios.post(process.env.REACT_APP_DB_HOST +'/marketcap', { stockcode })
      // console.log("marketcap fetching Fundamental data..")
      setFunData(res.data)
    //   setRoxData(res.data.rox)
    } 
  }
  getRecord()
  },[])

  return (
    <Stack spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <ThemeProvider theme={TITLE_THEME}>
            
            <Box sx={{ mt:'30px' }} />

            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num1">
                <Typography component="h5" variant="title" className="stockTitle">
                    PER
                </Typography>
            </Box>
            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <PerChart value={funData} />
              </Card>
            </Box>   


            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num2">
              <Typography component="h5" variant="title" className="stockTitle">
                  PBR
              </Typography>
            </Box>
            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <PbrChart value={funData} />
              </Card>
            </Box>          

            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num3">
              <Typography component="h5" variant="title" className="stockTitle">
                  PSR
              </Typography>
            </Box>
            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <PsrChart value={funData} />
              </Card>
            </Box>           

            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num4">
              <Typography component="h5" variant="title" className="stockTitle">
                  EV/EBIT
              </Typography>
            </Box>
            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <EvebitChart value={funData} />
              </Card>
            </Box>    

          </ThemeProvider>
    </Stack>
  );
}

export default Valuation;
