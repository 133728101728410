import * as React from 'react';
import ApexCharts from 'react-apexcharts';
import { useState, useEffect } from 'react';

export default function DetailEPS(funData) {

  const [result, setResult] = useState([])
  const [yoy, setyoy] = useState([])
  const [days, setDays] = useState([])

  useEffect( () => {
    function getRecord() {
        let result = [];
        let days = [];
        let yoy = [];

        funData.value.slice(0,5).reverse().forEach( values => {
                                          result.push({x:values.calendardate.toString().substr(0,7), y:values.epsdil.toFixed(2)})
                                          days.push(values.calendardate.toString().substr(0,7))
        })
        funData.value.forEach( (values, idx) => {
          if (idx < (funData.value.length-1) ) {
            // // console.log("idx: ", idx, "prev: ", values.epsdil, "forward: ", funData.value[idx+1].epsdil, "yoy: ", ((funData.value[idx+1].epsdil - values.epsdil) / values.epsdil))
            yoy.push( { x: funData.value[idx].calendardate.toString().substr(0,7) , y: (((values.epsdil - funData.value[idx+1].epsdil) / funData.value[idx+1].epsdil) * 100).toFixed(2)})
          }
        })

        
        yoy.reverse()
        // yoy.shift()
        
        // result.shift()
        // days.shift()
        // // console.log("Result: ", result)
        // // console.log("Days: ", days)
        // // console.log("YoY: ", yoy)
        setResult(result)
        setyoy(yoy)
        setDays(days)
      }
      getRecord()
      }, [funData])

        let data = {
    
            series: [{
                name: '수정 EPS($)',
                type: 'column',
                data: result
              }, {
                name: 'YoY(%)',
                type: 'line',
                data: yoy
              }],
              options: {
                chart: {
                  height: 350,
                  type: 'line',
                  animations: {
                    enabled: false,
                  },
                  toolbar: {
                      show: false,
                  }
                },
                stroke: {
                  width: [0, 4]
                },
                colors: ['#9AB3C1', '#82CA4A'],
                title: {
                //   text: 'Traffic Sources'
                },
                legend: {
                  position: "bottom"
                },
                dataLabels: {
                  enabled: true,
                  // enabledOnSeries: [1]
                },
                // labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                labels: days,
                plotOptions: {
                  bar: {
                    columnWidth: '20%',
                    barHeight: '100%'
                  }
                },
                xaxis: {
                  // type: 'datetime'
                  categories: days
                },
                yaxis: [{
                  // title: {
                  //   text: 'EPS(금액)',
                  // },
                
                }, {
                  opposite: true,
                  // title: {
                  //   text: 'YoY(%)',
                  // }
                }]
              },
          
      }      

        return (
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="line" width={'100%'} height={'300px'}/>
            </div>
        );
    
}
