import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import SearchBox from '../searchBox';
import MainListedStock from '../mainListedStock';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import AdvancerDecliner from '../advancerDecliner';
import { Link } from "react-router-dom";
import MarketIndexChart from './marketIndexChart';
import SectormapChart from './sectormapChart';
import TITLE_THEME from '../../customTheme.js';
import HeaderSub from '../../components/HeaderSub';

function BillBoard() {

  // const [rowData, setRowData] = useState([]);
  const [recommend, setRecommend] = useState([]);
  const [funData, setFunData] = useState([]);
  const [section, setSection] = useState('dow');
  const [period, setPeriod] = useState(5);
  const [sectorData, setSectorData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const onSelect = useCallback(
    text => {
      setSection(text)
      // console.log(section)
      }, [section],
    )
  const onPeriod = useCallback(
    text => {
      setPeriod(text)
      // console.log("period:", period)
      }, [period],
    )

  useEffect( () => {
    async function getRecord() {
      // const response = await axios.get(process.env.REACT_APP_DB_HOST + '/mainpage')
      const recom = await axios.get(process.env.REACT_APP_DB_HOST +'/recommend') 
      const fun = await axios.get(process.env.REACT_APP_DB_HOST + '/mkindex')
      const sec = await axios.get(process.env.REACT_APP_DB_HOST + '/sectormap')
      const indus = await axios.get(process.env.REACT_APP_DB_HOST + '/sectorprice')
      setSectorData(sec.data)
      setIndustryData(indus.data)
      // setRowData(response.data)
      setRecommend(recom.data)
      setFunData(fun.data)
    }
    getRecord()
  },[])

  function PrintPrice({value}) {
    if (value.pct_change > 0) {
      return (
          <font color="#FF303F">{value.close}</font>
      )
    } else {
      return (
          <font color="#008DDD">{value.close}</font>
      )
    }
  }

  const concatUrl = (code) => "/sectorselection/".concat(code)
  const extractTextPattern = /(<([^>]+)>)/gi;

  const menus = {
    'Financial Services' :'fin',
    'Real Estate' : 'real',
    'Technology': 'tech',
    'Energy': 'eneg',
    'Consumer Defensive':'csdefen',
    'Communication Services':'commun',
    'Industrials':'indus',
    'Healthcare':'health',
    'Consumer Cyclical' : 'cscycl',
    'Utilities':'util',
    'Basic Materials' : 'material'
}

const menusToName = {
  'Financial Services' :'금융',
  'Real Estate' : '부동산',
  'Technology': '정보기술',
  'Energy': '에너지',
  'Consumer Defensive':'필수소비재',
  'Communication Services':'커뮤니케이션 서비스',
  'Industrials':'산업재',
  'Healthcare':'헬스케어',
  'Consumer Cyclical' : '임의소비재',
  'Utilities':'유틸리티',
  'Basic Materials' : '소재'
}

let sector = ""

  return (
      <Box sx={{width:'100%'}}>
          <ThemeProvider theme={TITLE_THEME}>

          <HeaderSub title="전광판" />


            <Box className="sectionMarket typeSub">

              <Card variant="outlined" onClick={() => onSelect("dow")}>
                  <CardContent>                    
                 
                        <div>
                          <Typography variant="h5" component="div" color="text.secondary">
                            다우
                          </Typography>
                          <Typography variant="h5" color="black">
                            { Array.isArray(funData)&& funData.length > 0 ? funData[funData.length - 1].dow :null}
                          </Typography>
                          <Typography>
                            { Array.isArray(funData)&& funData.length > 0 ? 
                            funData[funData.length - 1].dowpct > 0 ?
                            <span className='stock-up'>{funData[funData.length - 1].dowpct}</span>
                            :
                            <span className='stock-down'>{funData[funData.length - 1].dowpct}</span>
                            :null}% 
                          </Typography>                          
                        </div>
                                       
                  </CardContent>
                </Card>
              

                <Card variant="outlined" onClick={() => onSelect("sp500")}>
                  <CardContent>                    
         
                        <div>
                          <Typography variant="h5" component="div" color="text.secondary">
                            S&P 500
                          </Typography>
                          <Typography variant="h5" color="black">
                            { Array.isArray(funData)&& funData.length > 0 ? funData[funData.length - 1].sp500 :null}
                          </Typography>
                          <Typography>
                            { Array.isArray(funData)&& funData.length > 0 ? 
                            funData[funData.length - 1].sp500pct > 0 ?
                            <span className='stock-up'>{funData[funData.length - 1].sp500pct}</span>
                            :
                            <span className='stock-down'>{funData[funData.length - 1].sp500pct}</span>
                            :null}% 
                          </Typography>                           
                        </div>
                                      
                  </CardContent>
                </Card>

                <Card variant="outlined" onClick={() => onSelect("nasdaq")}>
                  <CardContent>                    
                  
                        <div>
                          <Typography variant="h5" component="div" color="text.secondary">
                            나스닥
                          </Typography>
                          <Typography variant="h5" color="black">
                            { Array.isArray(funData)&& funData.length > 0 ? funData[funData.length - 1].nasdaq :null}
                          </Typography>
                          <Typography>
                            { Array.isArray(funData)&& funData.length > 0 ? 
                            funData[funData.length - 1].naspct > 0 ?
                            <span className='stock-up'>{funData[funData.length - 1].naspct}</span>
                            :
                            <span className='stock-down'>{funData[funData.length - 1].naspct}</span>
                            :null}% 
                          </Typography>                          
                        </div>
                                    
                  </CardContent>
                </Card>

                <Card variant="outlined" onClick={() => onSelect("russell")}>
                  <CardContent>                    
                 
                        <div>
                          <Typography variant="h5" component="div" color="text.secondary">
                            러셀 2000
                          </Typography>
                          <Typography variant="h5" color="black">
                            { Array.isArray(funData)&& funData.length > 0 ? funData[funData.length - 1].russell :null}
                          </Typography>
                          <Typography>
                            { Array.isArray(funData)&& funData.length > 0 ? 
                            funData[funData.length - 1].ruspct > 0 ?
                            <span className='stock-up'>{funData[funData.length - 1].ruspct}</span>
                            :
                            <span className='stock-down'>{funData[funData.length - 1].ruspct}</span>
                            :null}% 
                          </Typography>                           
                        </div>
                                    
                  </CardContent>
                </Card>
            </Box>
           
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItem: 'center'}} className="chart">
              <Card variant="undefined" squre sx={{ width: '100%', p: 3}}>
                <MarketIndexChart value={funData} section={section}/>
              </Card>
            </Box>

            
            <Box className="sectionMap">
              <Box className="sectionHead">
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                  상승/하락 지도
                </Typography>
              </Box>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItem: 'center'}}>
                <Card variant="undefined" sx={{ width: '100%'}}>
                  <AdvancerDecliner />
                </Card>
              </Box>
            </Box>


            <Box className="sectionSector typeSub">
              <Box className="sectionHead">
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                  업종 지도
                </Typography>
              </Box>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItem: 'center'}}>
                <Card variant="undefined" sx={{ width: '100%'}}>
                  <SectormapChart rowData={sectorData} period={period}/>
                </Card>
              </Box>

              <Box className="sectorIc" sx={{ minWidth: 320, width:'100%', display: 'flex', justifyContent: 'spaceBetween', alignItems: 'center'}}>
              { period === 5 ?
                
                <Box className="tab on">
                  <Card variant="undefined">
                    <Typography variant="tab" component="div">
                      5일
                    </Typography>
                  </Card>
                </Box>
                :
                <Box className="tab">
                  <Card variant="outlined" sx={{ width: '100%'}} onClick={() => onPeriod(5)}>
                    <Typography variant="tab" component="div">
                      5일
                    </Typography>
                  </Card>
                </Box>
              }

                { period === 20 ? 
                <Box className="tab on">
                  <Card variant="undefined">
                    <Typography variant="tab" component="div">
                      20일
                    </Typography>
                  </Card>
                </Box>
                :
                <Box className="tab">
                  <Card variant="outlined" sx={{ width: '100%'}} onClick={() => onPeriod(20)}>
                    <Typography variant="tab" component="div">
                      20일
                    </Typography>     
                  </Card>
                </Box>
                }

                { period === 60 ?
                <Box className="tab on">
                  <Card variant="undefined">
                    <Typography variant="tab" component="div">
                      60일
                    </Typography>
                  </Card>
                </Box>
                :
                <Box className="tab">
                  <Card variant="outlined" sx={{ width: '100%'}} onClick={() => onPeriod(60)}>
                    <Typography variant="tab" component="div">
                      60일
                      </Typography>     
                  </Card>
                </Box>
                }              

                { period === 120 ?
                <Box className="tab on">
                  <Card variant="undefined">
                    <Typography variant="tab" component="div">
                      120일
                    </Typography>
                  </Card>
                </Box>       
                :
                <Box className="tab">
                  <Card variant="outlined" sx={{ width: '100%'}} onClick={() => onPeriod(120)}>
                    <Typography variant="tab" component="div">
                      120일
                    </Typography>     
                  </Card>
                </Box>
              }
              </Box>
            </Box>

            

            <Box className="sectionSectorFeature typeSub">
              <Box className="sectionHead">
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                  업종 특징주
                </Typography>
              </Box>

              <Box className="list" sx={{ minWidth: '320px', width:'100%', display: 'flex',alignItems: 'stretch', flexWrap: 'wrap', alignContent:'flex-start'  }} >
                    {industryData.map((row, idx) => {
                        if (row.sector !== sector ) {
                            sector = row.sector
                            return (
                              <Card variant="undefined">
                                  <Link to={concatUrl(menus[row.sector])} style={{ textDecoration: 'none', color: 'black' }} >  
                                        <Typography variant="sector" component="div">
                                            {row.sector ? menusToName[row.sector]:null}
                                        </Typography>
                                        <Typography variant="stockTitle" component="div">
                                            <strong>{row.name_korean ? row.name_korean:null}</strong><br />
                                            <small>{row.ticker ? row.ticker:null}</small>
                                        </Typography>
                                        <Typography variant="price" component="div">
                                            {row.close ? row.close:null}
                                            &nbsp;&nbsp;
                                            <small>
                                              {row.pct_change? 
                                                row.pct_change < 0 ? 
                                                    <span class="stock-down">{(row.pct_change*100).toFixed(2)}%</span>
                                                    : <span class="stock-up">{(row.pct_change*100).toFixed(2)}%</span>
                                            :null}
                                            </small>
                                        </Typography>                                     
                                        {/* <Typography variant="price2" component="div">

                                        </Typography>      */}
                                  </Link>
                              </Card>
                              
                            )
                        }
                        return null
                    }
                    )}
                </Box>
            </Box>
            


            

          </ThemeProvider>
      </Box>
  );
}


export default BillBoard;
