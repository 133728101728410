import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import DetailEPS from './detailEPS';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DetailROE from './detailROE';
import DetailDPS from './detailDPS';
import DetailRevenue from './detailRevenue';
import DetailEbit from './detailEbit';
import DetailNetincome from './detailNetincome';
import DetailDebtRatio from './detailDebtRatio';
import DetailCurrentRatio from './detailCurrentRatio';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import DPS from './dividend/dps';
import Divyield from './dividend/divyield';
import Payoutratio from './dividend/payoutratio';
import Fcfps from './dividend/fcfps';
import TITLE_THEME from '../../customTheme.js';

import Radio from '@mui/material/Radio';

function Dividend({onSelect}) {

  const toApp = useCallback(
    text => {
        onSelect(text)
        // e.preventDefault()
    }, [onSelect]
  )
  const [funData, setFunData] = useState([]);
  const { stockcode } = useParams()

  useEffect( () => {
    async function getRecord() {

    if (typeof stockcode !== 'undefined') {
      const res = await axios.post(process.env.REACT_APP_DB_HOST +'/dividend', { stockcode })
      // console.log("Perfqter fetching Fundamental data..")
      setFunData(res.data)
      // // console.log("fundata[4]: ", funData[4])
      // // console.log("fundata[0]: ", funData[0])

    } 
  }
  getRecord()
  },[])

  return (
    <Stack spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <ThemeProvider theme={TITLE_THEME}>
            
            <Box sx={{ mt:'30px' }} />

            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num1">
              <Typography component="h5" variant="title" className="stockTitle">
                  DPS
              </Typography>

              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                  <CardContent>
                    <Typography variant="none" component="h6">
                    최근Q 주당배당금
                    </Typography>
                    <Typography variant="none" component="p">
                    { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.dps ? '$' + item.dps.toFixed(2) : null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>
                <Card variant="undefined" square sx={{ minWidth: 100 }}>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      YoY
                    </Typography>
                    <Typography variant="none" component="p">
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? ( (item.epsdil - funData[idx+4].epsdil) / funData[idx+4].epsdil * 100).toFixed(2) + '%': null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <DPS value={funData} />
              </Card>
            </Box>

    

            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num2">
              <Typography component="h5" variant="title" className="stockTitle">
                배당 수익률
              </Typography>
            </Box>
            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <Divyield value={funData} />
              </Card>
            </Box>   



            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num3">
              <Typography component="h5" variant="title" className="stockTitle">
                배당성향
              </Typography>
            </Box>
            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <Payoutratio value={funData} />
              </Card>
            </Box>   


            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num4">
              <Typography component="h5" variant="title" className="stockTitle">
                주당잉여현금
              </Typography>

              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      최근Q 주당잉여현금
                    </Typography>
                    <Typography variant="none" component="p">
                      { funData.map( (item, idx) => (
                          <div> { idx===0 ? item.fcfps ? '$' + item.fcfps : null : null} </div>
                        ))}
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square sx={{ minWidth: 100 }}>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      YoY
                    </Typography>
                    <Typography variant="none" component="p">
                      { funData.map( (item, idx) => (
                          <div> { idx===0 ? '$' + ((item.fcfps - funData[idx+4].fcfps) / funData[idx+4].fcfps * 100).toFixed(2) : null} </div>
                        ))}
                    </Typography>
                  </CardContent>
                </Card>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <Fcfps value={funData} />
              </Card>
            </Box>   



          </ThemeProvider>
    </Stack>
  );
}


export default Dividend;
