import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SearchBox from './searchBox';
import HeaderSub from '../components/HeaderSub';
import { Typography } from '@mui/material';
import TITLE_THEME from '../customTheme.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

  function PrettyName({value}) {
    const lim = 15
    let tt = value.name
    if (tt.length > lim) {
      tt = tt.slice(0, lim) + ".."
    } 
    return (
      // <List>
      //   <ListItem>{ tt } <br/> { value.cname }</ListItem>
      // </List>
      <div>
      { tt } <br/> { value.cname }
      </div>
    )
  }

function PrettyString({value}) {
  const limit = 70
  let tt = ""
  if (String(value).length > limit) {
    tt = value.slice(0, limit) + ".."
  } else {
    tt = value
  }
  return (
    <List>
      <ListItem>{ tt }</ListItem>
    </List>
  )
}


export default function SearchResult(props) {

  const [rowData, setRowData] = useState([]);

  // useEffect( () => {
  //     axios.get(process.env.REACT_APP_DB_HOST +'/company', {}).then(function (response){
  //         var data = response.data;
  //         // console.log(data);
  //         setRowData(data);
  //     })
  // },[])
  const { keyword } = useParams()

  useEffect( () => {
    async function getRecord() {

      if (typeof keyword !== 'undefined') {
        const response = await axios.post(process.env.REACT_APP_DB_HOST +'/basic', { keyword })
        // console.log(response.data);
        setRowData(response.data);
      } else {
        const response = await axios.get(process.env.REACT_APP_DB_HOST +'/basic')
        // console.log(response.data);
        setRowData(response.data);
      }
      // var data = response.data;
      // // console.log(response.data);
      // setRowData(response.data);
    }
    getRecord()
  },[])

 const concatUrl = (code) => "/detailstock/".concat(code)

  return (     
    <ThemeProvider theme={TITLE_THEME}>


    <Box sx={{width:'100%'}}>
      <HeaderSub title="검색결과" /> 

      <Box className="searchWrap" sx={{ bgcolor: 'primary', display: 'block', justifyContent: 'center', alignItems: 'center' }}>
        <SearchBox />
      </Box>


      <TableContainer component={Paper} sx={{width: '100%'}}>
        <Table sx={{ minWidth: 320 }} aria-label="customized table" className="searchResult">
          <TableHead>
            {/* <TableRow style={{ height: 30}}>
              <StyledTableCell>섹터</StyledTableCell>
              <StyledTableCell align="left">종목명</StyledTableCell>
              <StyledTableCell align="center">개요</StyledTableCell>
              <StyledTableCell align="right">시총(元)</StyledTableCell>
              <StyledTableCell align="right">일(%)</StyledTableCell>
            </TableRow> */}
          </TableHead>
          <TableBody>
            {rowData.map((row) => (
              <Link to={concatUrl(row.ticker)} style={{ textDecoration: 'none' }}>
                <StyledTableRow key={row.ticker} sx={{width:'100%'}}>
                  <StyledTableCell className="title" align="left">
                    <Typography variant="ticker" component="div">
                      {row.ticker}
                    </Typography>
                    <Typography variant="h5" component="div">
                      <PrettyName value={row} />
                    </Typography>
                  
                    <PrettyString value={row.name_korean} />
                  </StyledTableCell>
                  {/* <StyledTableCell className="title-kr" align="left">
                      
                  </StyledTableCell> */}

                  <StyledTableCell className="industry" align="left">{row.industry}</StyledTableCell>

                </StyledTableRow>
              </Link>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </ThemeProvider>
  );
}