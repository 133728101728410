import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import ApexCharts from 'react-apexcharts';
import { useState, useEffect } from 'react';
import axios from 'axios';
// import industry from './data/industryData.json';
// 차트 높이와 여백을 조금 조정해야 합니다. 
// 이건 정대표님 있을 때 항목을 확인해가면서 하는게 좋을 것 같습니다.

const data = {
        series: [{
            name: '종목수',
            data: [ 
                { x: '29',
                  y: 29,
                  fillColor: '#4881D6' // Blue
                }, { 
                  x: '19',
                  y: 19,
                  fillColor: '#4881D6'
                }, { 
                  x: '167',
                  y: 167,
                  fillColor: '#4881D6',
                }, {
                  x: '446',
                  y: 446,
                  fillColor: '#4881D6'
                }, {
                  x: '1875',
                  y: 1875,
                  fillColor: '#4881D6',
                  strokeColor: '#C23829'
                }, {
                  x: '508',
                  y: 508,
                  fillColor: '#C6CCD5' // Center
                }, {
                  x: '2614',
                  y: 2614,
                  fillColor: '#FF5C00' // Red
                }, {
                  x: '1549',
                  y: 1549,
                  fillColor: '#FF5C00'
                }, {
                  x: '622',
                  y: 622,
                  fillColor: '#FF5C00'
                }, {
                  x: '125',
                  y: 125,
                  fillColor: '#FF5C00'
                }, {
                  x: '73',
                  y: 73,
                  fillColor: '#FF5C00'
                }],
        }],
      // series: industry['series'],
      options: {
        chart: {
        //   height: 350,
          type: 'bar',
          parentHeightOffset: 0
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        fill : {
          opacity: 1
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        
        xaxis: {
          categories: ["<-15%", "-15~-10%", "-10~-5%", "-5~-2%", "-2~0%", "0", "0~2%", "2~5%", "5~10%", "10~15%", ">15%"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }
        
        },
        // title: {
        //   text: 'Advancers & Decliners',
        //   floating: true,
        //   offsetY: 0,
        //   align: 'center',
        //   style: {
        //     color: '#444'
        //   }
        // }
      
    
    }
}

export default function AdvancerDecliner() {
  const [rowData, setRowData] = useState([]);
  useEffect( () => {
    async function getRecord() {
      const response = await axios.get(process.env.REACT_APP_DB_HOST + '/advancer')
      setRowData(response.data);
    }
    getRecord()
  },[])

  let points = []
  const minus_group = [ 'A~-15%', 'B-15~-10%', 'C-5~-10%', 'D-2~-5%', 'E-2~0%' ]
  const plus_group = [ 'G0~2%', 'H2~5%', 'I5~10%', 'J10~15%', 'K15%~' ]
  rowData.forEach(value => {
    if(minus_group.includes(value.section)){
      points.push({x: value.count.toString(), y: value.count, fillColor: '#4881D6'})
    } else if(plus_group.includes(value.section)){
      points.push({x: value.count.toString(), y: value.count, fillColor: '#FF5C00'})
    } else {
      points.push({x: value.count.toString(), y: value.count, fillColor: '#C6CCD5'})
    }
  })

  const data = {
    series: [{
        name: '종목수',
        data: points
      }],
    options: {
      chart: {
      //   height: 350,
        type: 'bar',
        parentHeightOffset: 0,
        animations: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      fill : {
        opacity: 1
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      
      xaxis: {
        categories: ["<-15%", "-15~-10%", "-10~-5%", "-5~-2%", "-2~0%", "0", "0~2%", "2~5%", "5~10%", "10~15%", ">15%"],
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          }
        }
      
      },
  }
  }  
        return (
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="bar" height={380} />
            </div>
        );
    
}