import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import logo from '../../src/logo_primary.svg';
// import SwipeableTemporaryDrawer from './Nav';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Divider from '@mui/material/Divider';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { ArrowBackIos } from '@mui/icons-material';
import InputBase from '@mui/material/InputBase';
import { useCallback } from 'react';
// 상단 헤더 파일입니다. 일단은 서브용. 뒤로가기 버튼과 소형 검색 기능이 들어갑니다. 검색기능은 일정이 여의치 않으면 후순위. 미사용 항목들 정리는 안했습니다.

export default function ButtonAppBar({title}) {
  const [searchWord, setSearchWord] = useState("")
  const onSubmit = e => {
    e.preventDefault()
    console.log(searchWord)
    // console.log(e.target[0].value)
    // window.location.href = "/list/" + e.target[0].value
    if(searchWord.length > 0){
      window.location.href = "/list/" + searchWord
    } else {
      return
    }

    }
  const toApp = useCallback(
    e => {
      setSearchWord(e.target[0].value)
    }, []
    )

  return (
    <div id="header" class="sub">
      <AppBar position="static" sx={{bgcolor:'transparent'}}>
        <Toolbar>
        
          <Box>
          <form onSubmit={onSubmit} style={{display: 'flex', width: '100%'}}>
            <IconButton
              href="javascript:window.history.back();" // 이전 페이지로 이동. 임의 코드로 넣었습니다. 
              size="large"
              edge="start"
              aria-label="back"
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              href="/" 
              size="large"
              edge="start"
              aria-label="home"
              sx={{p:0}}
            >
              <HomeIcon />
            </IconButton>
          
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              name="search"
              placeholder=""
              inputProps={{ 'aria-label': 'search theme', style: { fontSize: 16 } }}
              // onClick={(e) => toApp(e)}
              onChange={e => setSearchWord(e.target.value)}
            />

          </form>
          </Box>
          
        

          <Box className="pageTitle">
            <Typography variant="none" component="h2">{title}</Typography>
          </Box>

          
          <IconButton
              onClick={onSubmit} // (개발)나중에 검색창 펼쳐지도록 할 예정
              size="large"
              edge="end"
              aria-label="search"
              sx={{ ml: 0 }}
            >
              <SearchIcon />
          </IconButton>


          
        </Toolbar>
      </AppBar>
    </div>
  );
}

