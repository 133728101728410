import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import SearchBox from './searchBox';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import logo from '../../src/logo.svg';
import logoPrimary from '../../src/logo_primary.svg';
// import SwipeableTemporaryDrawer from './Nav';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
// 상단 헤더 파일입니다. 일단은 메인용/

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};



export default function ElevateAppBar(props) {
  const { window } = props;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onSelect = useCallback(
    open => {
      setState({ ...state, 'left' : open})
    }, [state],
  )

 
  

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="navigation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="drawer"
    >
      <Box sx={{p:3}} className="navHeader">
       <MenuOpenIcon 
          onClick={toggleDrawer(anchor, true)}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menuOpen"
          sx={{ mr: 1 }}
        />
        <a href="/">
          <img src={logoPrimary} className="App-logo-drawer" alt="파인애플"/>
        </a>
        
        <CloseIcon 
          onClick={toggleDrawer(anchor, true)}
          size="large"
          edge="end"
          color="inherit"
          aria-label="menuClose"
          sx={{ mr: 0 }}
        />
      </Box>

      <SearchBox onSelect={onSelect} />  {/* 여기에 클래스 지정하고 싶은데 가능할지? */}

      <List className='navList'>
        {/* {['오늘의 추천주', '전광판', '종목 발굴', '분석리포트'].map((text, index) => ( */}
        <Link to="/today">
          <ListItem>
              {/* <ListItemIcon>
              <InfoIcon />
            </ListItemIcon> */}
              <ListItemText id="switch-list-label-menu1" primary="오늘의 추천주" />
          </ListItem>
        </Link>
        <Link to="/billboard">
          <ListItem>
            {/* <ListItemIcon>
              <InfoIcon />
            </ListItemIcon> */}
            <ListItemText id="switch-list-label-menu2" primary="전광판" />
          </ListItem>
        </Link>
        <Link to="/selection/vscore">
          <ListItem>
            {/* <ListItemIcon>
              <InfoIcon />
            </ListItemIcon> */}
            <ListItemText id="switch-list-label-menu3" primary="종목 발굴" />
          </ListItem>
        </Link>
        <Link to="/sectorselection/tech">
          <ListItem>
            {/* <ListItemIcon>
              <InfoIcon />
            </ListItemIcon> */}
            <ListItemText id="switch-list-label-menu4" primary="업종특징주" />
          </ListItem>
        </Link>
        <Link to="#4">
          <ListItem>
            {/* <ListItemIcon>
              <InfoIcon />
            </ListItemIcon> */}
            <ListItemText id="switch-list-label-menu4" primary="분석리포트" />
          </ListItem>
        </Link>
        <Link to="#5">
          <ListItem>
            {/* <ListItemIcon>
              <InfoIcon />
            </ListItemIcon> */}
            <ListItemText id="switch-list-label-menu5" primary="서비스 소개" />
          </ListItem>
        </Link>
        
      </List>

      <Box className='navFooter'>

          <ul class="serivceLinks">
            <li><a href="#">이용약관</a></li>
            <li><a href="#">회사소개</a></li>
          </ul>

          <p class="company">(주)한국투자교육연구소</p>
            
      </Box>
      
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  // const container = document.getElementById('header')
  // console.log("header Container:", container)
  const Root = styled('div')(({ theme }) => ({
    height: '100%',
    // backgroundColor:
    //   theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
  }));

  return (
    <div id="header">
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
      <CssBaseline />
      {/* <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50%)`,
            overflow: 'visible',
          },
        }}
      /> */}
      <Container className="AppHeader" maxWidth="sm" sx={{margin: 'auto', p:0}}>

        <ElevationScroll {...props}>
          <AppBar>
            <Toolbar>
                <MenuIcon 
                  onClick={toggleDrawer(anchor, true)}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menuBasic"
                  sx={{ mr: 1 }}
                />
              <div class="logoWrap">
                <a href="/">
                  <img src={logo} className="App-logo" alt="파인애플"/>
                  <p><strong>Fine-Apple</strong>,<br></br> 애플 같은 좋은 미국 주식 갖기 프로젝트</p>
                </a>
              </div>
            </Toolbar>
            <SwipeableDrawer
              sx={{
               
                position: "absolute",
                // position: 0,
                top: "0px",
                left:"0px",
                // marginleft: "35px",
                // marginbottom: "150px",
                // width: 200,
                // "& .MuiBackdrop-root": {
                //   display: "none"
                // },
                "& .MuiDrawer-paper": {
                  width: "350px",
                  position: "absolute",
                  height: "100vh",
                  // transition: "none !important"
                }
              }}
                  
                  // container={container}
                  variant="persistent"
                  // variant="temporary"
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  // swipeAreaWidth={500}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {list(anchor)}
            </SwipeableDrawer>
          </AppBar>

        </ElevationScroll>
        <Toolbar />

      </Container>

    </React.Fragment>
    ))}
    </div>
  );
}