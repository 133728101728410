import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import DetailStockChart from './detailStockChart';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Performance from './stock/performance';
import PerformanceYear from './stock/performanceYear';
import ProfitQter from './stock/profitQter';
import ProfitYear from './stock/profitYear';
import Valuation from './stock/valuation';
import Dividend from './stock/dividend';
import TITLE_THEME from '../customTheme.js';
import ComfortQter from './stock/comfortqter';
import ComfortYear from './stock/comfortYear';
import HeaderSub from '../components/HeaderSub';
import DetailStockVolume from './detailStockVolume';
import DetailStockRadar from './detailStockRadar';

const WhiteTextTypography = withStyles({
  root: {
      backgroundColor: "gray"
  }
})(Typography);

function DetailStock() {

  const [rowData, setRowData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [funData, setFunData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const { stockcode } = useParams()

  const [section, setSection] = useState('perfqter');
  const onSelect = useCallback(
    text => {
      setSection(text)
      // console.log(section)
    }, [section],
  )

  useEffect( () => {
    async function getRecord() {

    if (typeof stockcode !== 'undefined') {
      const response = await axios.post(process.env.REACT_APP_DB_HOST +'/prices', { stockcode })
      // console.log("fetching...")
      // console.log(response.data);
      setRowData(response.data);
      let result = [];
      result = response.data
      setChartData(result.reverse());

      const res = await axios.post(process.env.REACT_APP_DB_HOST +'/fundamental', { stockcode })
      setFunData(res.data)
      // console.log("fundata[4]: ", funData[4])
      // console.log("fundata[0]: ", funData[0])
      const market = await axios.post(process.env.REACT_APP_DB_HOST +'/marketcap', { stockcode })
      setMarketData(market.data)

    } else {
      const response = await axios.get(process.env.REACT_APP_DB_HOST +'/prices')
      // console.log(response.data);
      setRowData(response.data);
    }
  }
  getRecord()
  },[])
  return (
    <Stack spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <ThemeProvider theme={TITLE_THEME}>

            <HeaderSub title="" /> 
            {/* 종목 상세 페이지의 경우 종목명 집중을 위해 상단 타이틀을 표시하지 않습니다. 페이지 단계상 가장 최종 단계의 페이지로 볼 수 있어서 타이틀이 없어도 브라우징에 혼란을 야기하지 않을 것입니다.  */}

            <Box sx={{ minWidth: 320, width: '100%' }} className="stockHeader">
                <Card variant="undefined">
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {/* 애플 AAPL */}
                            { rowData.map((item, idx) => (
                              <div key={idx}>
                                 <span className="title">{ idx===0 ? item.BasicInfoEtc.name_korean : null } </span>
                                 <span className="ticker">{ idx===0 ? item.ticker : null}</span> </div>
                              )
                            )}
                        </Typography>
                        <Typography sx={{ mb: 0 }} color="text.secondary">
                            {/* 172.90  ▼2.94  <font color="blue">-1.67%</font> */}
                            { rowData.map((item, idx) => (
                              <div key={idx}> 
                              {idx===(rowData.length-1) ? <span class="price-present">{item.close}</span> : null }
                               {idx===(rowData.length-1) ? item.pct_change < 0 ? <span className="stock-down">{item.pct_change ? (item.pct_change*100).toFixed(2)+ '%':null}</span> : <span className="stock-up">{item.pct_change ? (item.pct_change*100).toFixed(2) + '%':null}</span> : '' } </div>
                              )
                            )}
                        </Typography>
                        <Typography className="industry">
                            {/* 나스닥 시총 1위 */}
                            { rowData.map((item, idx) => (
                              <div key={idx}> { idx===0 ? item.Basic.industry:null} </div>
                              )
                            )}
                        </Typography>
                    </CardContent>  
                </Card>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="stockGraph">
              <Card sx={{ height: '350px' }} variant="undefined" square>
                <DetailStockChart chartData={chartData}/>
              </Card>
              <Card sx={{ height: '150px' }} variant="undefined" square>
                <DetailStockVolume chartData={chartData}/>
              </Card>
              <Card sx={{ height: '300px' }} variant="undefined" square>
                <DetailStockRadar fun={funData}/>
              </Card>
            </Box>     

            <Box sx={{ minWidth: 320, width: '100%' }} className="stockData">
              <Typography component="h5" variant="title" className="stockTitle">
                  핵심 투자지표
              </Typography>
            
              <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} className="list">
                <Card variant="undefined" square>
                  <CardContent>                  
                    <Typography variant="none" component="h6">
                      PER
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.pe ? item.pe.toFixed(2) + '배' : null : null} </div>
                      ))} */}
                      { marketData.map( (item, idx) => (
                        <div> { idx===0 ? item.pe ? item.pe.toFixed(2) + '배' : null : null} </div>
                      ))}

                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      PBR
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.pb ? item.pb.toFixed(2) + '배': null : null} </div>
                      ))} */}
                      { marketData.map( (item, idx) => (
                        <div> { idx===0 ? item.pb ? item.pb.toFixed(2) + '배' : null : null} </div>
                      ))}                      
                    </Typography>
                  </CardContent>
                </Card>
                
                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      PSR
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.ps ? item.ps.toFixed(2) + '배': null: null} </div>
                      ))} */}
                      { marketData.map( (item, idx) => (
                        <div> { idx===0 ? item.ps ? item.ps.toFixed(2) + '배' : null : null} </div>
                      ))}                      
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      EV/EBITDA
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* { funData.map( (item, idx) => (
                        <div> { idx===0 ?  item.ev && item.ebitda ? (item.ev / item.ebitda).toFixed(2) + '배' : null : null} </div>
                      ))} */}
                      { marketData.map( (item, idx) => (
                        <div> { idx===0 ? item.evebitda ? item.evebitda.toFixed(2) + '배' : null : null} </div>
                      ))}                 
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      영업이익률
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* 12.40% */}
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.ebit && item.revenue ? (item.opinc / item.revenue * 100).toFixed(2) + '%': null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      순이익률
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* 10.58% */}
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.netinccmn && item.revenue ? (item.netinccmn / item.revenue * 100).toFixed(2) + '%': null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      ROE
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* 15.07% */}
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.roe ? (item.roe*100).toFixed(2) + '%': null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      ROA
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* 8.34% */}
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.roa ? (item.roa*100).toFixed(2) + '%': null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      ROIC
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* 21.25% */}
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.roic ? (item.roic*100).toFixed(2) + '%': null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      주당배당금(연)
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* $5.61 */}
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.dps ? '$' + item.dps.toFixed(2) : null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      배당수익률
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* 3.51% */}
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.divyield ? (item.divyield*100).toFixed(2) + '%': null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>

                <Card variant="undefined" square>
                  <CardContent>
                    <Typography variant="none" component="h6">
                      배당성향
                    </Typography>
                    <Typography variant="none" component="p">
                      {/* 70.58% */}
                      { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.dps && item.sharesbas && item.netinccmn ? (item.dps * item.sharesbas / item.netinccmn * 100).toFixed(2) + '%': null : null} </div>
                      ))}
                    </Typography>
                  </CardContent>
                </Card>
                
              </Box>

            </Box>


            <Box sx={{ minWidth: 320, width: '100%' }} className="stockVchart">
              <Typography component="h5" variant="title" className="stockTitle">
                  V-차트
              </Typography>
            
              <Box sx={{ minWidth: 320, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="tabMenu">
              
              { section === "perfqter" | section==="perfyear" ?
                <Box sx={{ width: '20%'}} className="tabMenu1 on">
                  <Card variant="undefined" square>
                    <Typography variant="tab" component="div" align="center">
                      실적
                    </Typography>
                  </Card>
                </Box>
                :
                <Box sx={{ width: '20%'}} className="tabMenu1">
                  <Card variant="undefined" square onClick={() => onSelect("perfqter")}>
                    <Typography variant="tab" component="div" align="center">
                      실적
                    </Typography>
                  </Card>
                </Box>
              }

                { section==="profitqter" | section==="profityear" ? 
                <Box sx={{ width: '20%'}} className="tabMenu2 on">
                  <Card variant="undefined" square>
                      <Typography variant="tab" component="div" align="center">     
                          수익성
                      </Typography>     
                  </Card>
                </Box>
                :
                <Box sx={{ width: '20%'}} className="tabMenu2">
                <Card variant="undefined" square onClick={() => onSelect("profitqter")}>
                    <Typography variant="tab" component="div" align="center">     
                        수익성
                    </Typography>     
                </Card>
              </Box>
                }

                { section === "valuation" ?
                <Box sx={{ width: '20%'}} className="tabMenu3 on">
                  <Card variant="undefined" square>
                    <Typography variant="tab" component="div" align="center">
                          밸류에이션
                      </Typography>     
                  </Card>
                </Box>
                :
                <Box sx={{ width: '20%'}} className="tabMenu3">
                  <Card variant="undefined" square onClick={() => onSelect("valuation")} >
                      <Typography variant="tab" component="div" align="center"> 
                          밸류에이션
                      </Typography>     
                  </Card>
                </Box>
                }              

                { section === "dividend" ?
                  <Box sx={{ width: '20%'}} className="tabMenu4 on">
                  <Card variant="undefined" square>
                    <Typography variant="tab" component="div" align="center">
                          배당
                      </Typography>     
                  </Card>
                </Box>              
                :
                  <Box sx={{ width: '20%'}} className="tabMenu4">
                  <Card variant="undefined" square onClick={() => onSelect("dividend")}>
                    <Typography variant="tab" component="div" align="center"> 
                          배당
                      </Typography>     
                  </Card>
                </Box>                            
              }

                { section === "comfortqter" | section === "comfortyear" ?
                  <Box sx={{ width: '20%'}} className="tabMenu5 on">
                    <Card variant="undefined" square>
                      <Typography variant="tab" component="div" align="center">
                            안정성
                        </Typography>     
                    </Card>
                  </Box>
                  :
                  <Box sx={{ width: '20%'}} className="tabMenu5">
                    <Card variant="undefined" square onClick={() => onSelect("comfortqter")}>
                      <Typography variant="tab" component="div" align="center"> 
                            안정성
                        </Typography>     
                    </Card>
                  </Box>
                }


              </Box>
              <Box className="list">
              {
                  {
                    'perfqter' : <Performance onSelect={onSelect}/>,
                    'perfyear' : <PerformanceYear onSelect={onSelect}/>,
                    'profitqter' : <ProfitQter onSelect={onSelect}/>,
                    'profityear' : <ProfitYear onSelect={onSelect}/>,
                    'valuation' : <Valuation />,
                    'dividend' : <Dividend />,
                    'comfortqter' : <ComfortQter onSelect={onSelect}/>,
                    'comfortyear' : <ComfortYear onSelect={onSelect}/>
                  }[section]
                  }
              </Box>

            </Box>


            

           
          </ThemeProvider>
    </Stack>
  );
}
export default DetailStock;
