import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import DetailEPS from './detailEPS';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DetailROE from './detailROE';
import DetailDPS from './detailDPS';
import DetailRevenue from './detailRevenue';
import DetailEbit from './detailEbit';
import DetailNetincome from './detailNetincome';
import DetailCurrentRatio from './detailCurrentRatio';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import DetailOpMargin from './profitQter/detailOpMargin';
import DetailNetMargin from './profitQter/detailNetMargin';
import DetailRoe from './profitQter/detailRoe';
import DetailRoa from './profitQter/detailRoa';
import DetailRoic from './profitQter/detailRoic';
import ComfortDebtRatio from './comfort/comfortDebtRatio';
import ComfortCurrentRatio from './comfort/comfortCurrentRatio';
import ComfortInterestCoverageRatio from './comfort/comfortInterestCoverageRatio';
import Zscore from './comfort/zscore';
import TITLE_THEME from '../../customTheme.js';

import Radio from '@mui/material/Radio';

function ComfortYear({onSelect}) {

  const toApp = useCallback(
    text => {
        onSelect(text)
        // e.preventDefault()
    }, [onSelect]
  )
  const [funData, setFunData] = useState([]);
  const [roxData, setRoxData] = useState([]);
  const { stockcode } = useParams()

  useEffect( () => {
    async function getRecord() {

    if (typeof stockcode !== 'undefined') {
      const res = await axios.post(process.env.REACT_APP_DB_HOST +'/comforty', { stockcode })
      // console.log("comfortQter fetching Fundamental data..")
      setFunData(res.data.de)
      setRoxData(res.data.zscore)
    } 
  }
  getRecord()
  },[])

  return (
    <Stack spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <ThemeProvider theme={TITLE_THEME}>

          <Box sx={{ minWidth: 320, display: 'flex', justifyContent:'flex-end' }} className="radioSection">
              <label><Radio name="period" size='small' onClick={() => toApp("comfortqter")} /> 분기</label>
              <label><Radio name="period" size='small' checked  /> 연간</label>
            </Box>


            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num1">
              <Typography component="h5" variant="title" className="stockTitle">
                부채비율
              </Typography>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <ComfortDebtRatio value={funData} />
              </Card>
            </Box>   

       
    

            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num2">
              <Typography component="h5" variant="title" className="stockTitle">
                유동비율
              </Typography>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <ComfortCurrentRatio value={funData} />
              </Card>
            </Box>   



            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num3">
              <Typography component="h5" variant="title" className="stockTitle">
                이자보상배율
              </Typography>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <ComfortInterestCoverageRatio value={funData} />
              </Card>
            </Box>   




            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num4">
              <Typography component="h5" variant="title" className="stockTitle">
                Z-점수
              </Typography>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <Zscore value={roxData} />
              </Card>
            </Box>   


           


          </ThemeProvider>
    </Stack>
  );
}

export default ComfortYear;
