import * as React from 'react';
import ApexCharts from 'react-apexcharts';
import { useState, useEffect } from 'react';

export default function PsrChart(funData) {

  const [result, setResult] = useState([])
  // const [yoy, setyoy] = useState([])
  const [days, setDays] = useState([])

  useEffect( () => {
    function getRecord() {
        let result = [];
        let days = [];
        // let yoy = [];

        funData.value.reverse().forEach( values => {
                                          result.push({x:values.date.toString().substr(0,10), y:(values.ps).toFixed(1)})
                                          days.push(values.date.toString().substr(0,10))
        })

        // console.log("per length: ", result.length)
        
        // yoy.reverse()
        // yoy.shift()
        
        setResult(result)
        // setyoy(yoy)
        setDays(days)
      }
      getRecord()
      }, [funData])

        let data = {
    
            series: [{
                name: 'PSR',
                type: 'line',
                data: result
              } 
              // {
              //   name: 'YoY(%)',
              //   type: 'line',
              //   data: yoy
              // }
            ],
              options: {
                chart: {
                  height: 350,
                  type: 'line',
                  animations: {
                    enabled: false,
                  },
                  toolbar: {
                      show: false,
                  }
                },
                // stroke: {
                //   width: [0, 4]
                // },
                colors: ['#82CA4A','#9AB3C1' ],
                title: {
                //   text: 'Traffic Sources'
                },
                legend: {
                  position: "top"
                },
                dataLabels: {
                  enabled: false,
                  // enabledOnSeries: [1]
                },
                labels: days,
                xaxis: {
                  type: 'datetime',
                  categories: days,
                  tickAmount: 50,
                },
                yaxis: [{
                  // title: {
                  //   text: 'EPS(금액)',
                  // },
                
                }, 
                // {
                //   opposite: true,
                // }
              ]
              },
          
      }      

        return (
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="line" width={'100%'} height={'300px'}/>
            </div>
        );
    
}
