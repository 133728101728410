import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import SearchBox from '../searchBox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import TITLE_THEME from '../../customTheme.js';
import HeaderSub from '../../components/HeaderSub';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const menuToValue = {
  'fin' :0, 
  'real':1,
  'tech':2,
  'eneg':3,
  'csdefen':4,
  'commun':5,
  'indus':6,
  'health':7,
  'cscycl':8,
  'util':9,
  'material':10
}

function SectorSelection() {

  const { menu } = useParams()
  const [recommend, setRowData] = useState([]);
  // const [stockup, setStockUp] = useState([])
  // const [stockdown, setStockDown] = useState([])
  // const [volumnbig, setVolumnBig] = useState([])
  const [section, setSection] = useState("represent")
  const [categoryNumber, setCategoryNumber] = useState(menuToValue[menu]);

  useEffect( () => {
    async function getRecord() {
      const response = await axios.get(process.env.REACT_APP_DB_HOST + '/sectorprice')
      setRowData(response.data);
      // setStockDown(response.data.sort(function (a, b) { return a.pct_change - b.pct_change}))
      // setStockUp(response.data.sort(function (a, b) { return b.pct_change - a.pct_change}))
      // setVolumnBig(response.data.sort(function (a, b) { return b.volumn - a.volumn}))
    }
    getRecord()
  },[])

  // // console.log("stockup:", stockup, "stockdown:", stockdown, "volumnbig:", volumnbig)

  function PrintPrice({value}) {
    if (value.pct_change > 0) {
      return (
          <span class="stock-up">{(value.pct_change*100).toFixed(2)}<small>%</small></span>
      )
    } else {
      return (
          <span class="stock-down">{(value.pct_change*100).toFixed(2)}<small>%</small></span>
      )
    }
  }

  function IndexUpDown({value}) {
    if (value.percent > 0) {
      return (
          <span class="stock-up">{value.price}</span>
      )
    } else {
      return (
          <span class="stock-down">{value.price}</span>
      )
    }
  }
  function PrettyName(value) {
    const lim = 14
    let tt = value  //.replace(" ", "")
    if (tt && tt.length > lim) {
      tt = tt.slice(0, lim) + ".."
    } 
    return (
      // <List>
      //   <ListItem>{ tt } <br/> { value.cname }</ListItem>
      // </List>
      <div>
      { tt }
      </div>
    )
  }
  const menus = { // 기존탭
      'fin': 'Financial Services',
      'real' : 'Real Estate',
      'tech' : 'Technology',
      'eneg' : 'Energy',
      'csdefen' : 'Consumer Defensive',
      'commun' : 'Communication Services',
      'indus' : 'Industrials',
      'health' : 'Healthcare',
      'cscycl' : 'Consumer Cyclical',
      'util' : 'Utilities',
      'material' : 'Basic Materials'
  }



  const valueToMenu = {
    0: 'fin', 
    1: 'real',
    2: 'tech',
    3: 'eneg',
    4: 'csdefen',
    5: 'commun',
    6: 'indus',
    7: 'health',
    8: 'cscycl',
    9: 'util',
    10: 'material'
  }

  const onSelect = useCallback(
    text => {
      setSection(text)
    }, [],
  )
  const concatUrl = (code) => "/detailstock/".concat(code)
//   const extractTextPattern = /(<([^>]+)>)/gi;

// https://mui.com/material-ui/react-tabs/#nav-tabs
function LinkTab(props) {
  return (
    <Tab
      component="div"
      onClick={(event) => {
        event.preventDefault();
        // console.log(event.target.label)
      }}
      {...props}
    />
  );
}



  const handleChange = (event, newValue) => {
    setCategoryNumber(newValue);
    // console.log("newValue:", newValue)
    // window.location.href = "/sectorselection/" + valueToMenu[newValue]
  };
  return (
      <Box sx={{width:'100%'}}>
        <HeaderSub title="업종 특징주" />


          <ThemeProvider theme={TITLE_THEME}>


          <Box sx={{ width:'100%'/*minWidth:320, maxWidth: { xs: 320, sm: 480 }*/ }} className="tabWrapAuto">
              <Tabs
                value={categoryNumber}
                onChange={handleChange}
                variant="scrollable"
                //scrollButtons="auto" // Auto로 하면 제대로 나오지 않아서 일단 강제로 함
                scrollButtons
                allowScrollButtonsMobile
                selectionFollowsFocus
                aria-label="scrollable auto tabs"
              >
                <LinkTab label="금융" />
                <LinkTab label="부동산" href="/sectorselection/real" />
                <LinkTab label="정보기술" href="/sectorselection/tech" />
                <LinkTab label="에너지" href="/sectorselection/eneg" />
                <LinkTab label="필수소비재" href="/sectorselection/csdefen" />
                <LinkTab label="통신서비스" href="/sectorselection/commun" />
                <LinkTab label="산업재" href="/sectorselection/indus" />
                <LinkTab label="헬스케어" href="/sectorselection/health" />
                <LinkTab label="자유소비재" href="/sectorselection/cscycl" />
                <LinkTab label="유틸리티" href="/sectorselection/util" />
                <LinkTab label="소재" href="/sectorselection/material" />

              </Tabs>
            </Box>
           


            <Box sx={{ minWidth: 320, width: '100%', display: 'flex', justifyContent: 'flexStart', alignItems: 'center'}} className="tabMenuSub">
              
              { section === "represent" ?
                <Box className="tabMenu1 on">
                  <Card variant="undefined" square>
                    <Typography variant="tab" component="div" align="center">
                      대표주
                    </Typography>
                  </Card>
                </Box>
                :
                <Box className="tabMenu1">
                  <Card variant="undefined" square onClick={() => onSelect("represent")}>
                    <Typography variant="tab" component="div" align="center">
                      대표주
                    </Typography>
                  </Card>
                </Box>
              }
              { section === "stockup" ?
                <Box className="tabMenu1 on">
                  <Card variant="undefined" square>
                    <Typography variant="tab" component="div" align="center">
                      상승주
                    </Typography>
                  </Card>
                </Box>
                :
                <Box className="tabMenu1">
                  <Card variant="undefined" square onClick={() => onSelect("stockup")}>
                    <Typography variant="tab" component="div" align="center">
                      상승주
                    </Typography>
                  </Card>
                </Box>
              }
              { section === "stockdown" ?
                <Box className="tabMenu1 on">
                  <Card variant="undefined" square>
                    <Typography variant="tab" component="div" align="center">
                      하락주
                    </Typography>
                  </Card>
                </Box>
                :
                <Box className="tabMenu1">
                  <Card variant="undefined" square onClick={() => onSelect("stockdown")}>
                    <Typography variant="tab" component="div" align="center">
                      하락주
                    </Typography>
                  </Card>
                </Box>
              }
              { section === "volumnbig" ?
                <Box className="tabMenu1 on">
                  <Card variant="undefined" square>
                    <Typography variant="tab" component="div" align="center">
                      거래량상위
                    </Typography>
                  </Card>
                </Box>
                :
                <Box className="tabMenu1">
                  <Card variant="undefined" square onClick={() => onSelect("volumnbig")}>
                    <Typography variant="tab" component="div" align="center">
                      거래량상위
                    </Typography>
                  </Card>
                </Box>
              }
            </Box>



            <Box  className="tabContentWrap" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap:'wrap' }}>

              { section==="represent" && Array.isArray(recommend) && recommend.length > 0 ? recommend.filter(item => item.sector===menus[valueToMenu[categoryNumber]] && item.sortingView==='marketcap').map( (item, idx) => (
                <Box sx={{ width:'50%' }} className="tabContentItem">
                  <Card variant="undefined">
                      <CardContent>
                        <Link to={concatUrl(item.ticker)} style={{ textDecoration: 'none', color: '$black-900' }}>

                          <Typography variant="stockTitle" component="div">
                            <strong>{item.name_korean}</strong>
                            <small>{item.ticker}</small>
                          </Typography>
                          <Typography variant="stockPrice" component="div">
                            <span class="body"> {item.close.toFixed(2)} </span>
                            <small><PrintPrice value={item} /></small>
                          </Typography>
                          
                        </Link>
                      </CardContent>
                  </Card>
                </Box>
              )):
              null}
              { section==="stockup" && Array.isArray(recommend) && recommend.length > 0 ? recommend.filter(item => item.sector===menus[valueToMenu[categoryNumber]] && item.sortingView==='stockup').map( (item, idx) => (
                <Box sx={{ width:'50%' }} className="tabContentItem">
                  <Card variant="undefined">
                      <CardContent>
                        <Link to={concatUrl(item.ticker)} style={{ textDecoration: 'none', color: '$black-900' }}>

                          <Typography variant="stockTitle" component="div">
                            <strong>{item.name_korean}</strong>
                            <small>{item.ticker}</small>
                          </Typography>
                          <Typography variant="stockPrice" component="div">
                          <span class="body"> {item.close.toFixed(2)} </span>
                            <small><PrintPrice value={item} /></small>
                          </Typography>
                          
                        </Link>
                      </CardContent>
                  </Card>
                </Box>
              )):
               null}
              { section==="stockdown" && Array.isArray(recommend) && recommend.length > 0 ? recommend.filter(item => item.sector===menus[valueToMenu[categoryNumber]] && item.sortingView==='stockdown').map( (item, idx) => (
                <Box sx={{ width:'50%' }} className="tabContentItem">
                  <Card variant="undefined">
                      <CardContent>
                        <Link to={concatUrl(item.ticker)} style={{ textDecoration: 'none', color: '$black-900' }}>

                        <Typography variant="stockTitle" component="div">
                            <strong>{item.name_korean}</strong>
                            <small>{item.ticker}</small>
                          </Typography>
                          <Typography variant="stockPrice" component="div">
                          <span class="body"> {item.close.toFixed(2)} </span>
                            <small><PrintPrice value={item} /></small>
                          </Typography>
                          
                        </Link>
                      </CardContent>
                  </Card>
                </Box>
              )):
              null}
              { section==="volumnbig" && Array.isArray(recommend) && recommend.length > 0 ? recommend.filter(item => item.sector===menus[valueToMenu[categoryNumber]] && item.sortingView==='volume').map( (item, idx) => (
                <Box sx={{ width:'50%' }} className="tabContentItem">
                  <Card variant="undefined">
                      <CardContent>
                        <Link to={concatUrl(item.ticker)} style={{ textDecoration: 'none', color: '$black-900' }}>

                        <Typography variant="stockTitle" component="div">
                            <strong>{item.name_korean}</strong>
                            <small>{item.ticker}</small>
                          </Typography>
                          <Typography variant="stockPrice" component="div">
                          <span class="body"> {item.close.toFixed(2)} </span>
                            <small><PrintPrice value={item} /></small>
                          </Typography>
                          
                        </Link>
                      </CardContent>
                  </Card>
                </Box>
              )):
              null}


            </Box>

          </ThemeProvider>
      </Box>
  );
}

export default SectorSelection;