import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import SearchBox from './searchBox';
import MainListedStock from './mainListedStock';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import AdvancerDecliner from './advancerDecliner';
import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import TITLE_THEME from '../customTheme.js';
import HeaderMain from '../components/HeaderMain';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { deepOrange, deepPurple } from '@mui/material/colors';
import * as ac from '@mui/material/colors'; // ac = Avatar Color
function MainBody() {

  const [rowData, setRowData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [recommend, setRecommend] = useState([]);
  useEffect( () => {
    async function getRecord() {
      const response = await axios.get(process.env.REACT_APP_DB_HOST + '/mkindex')
      const res = await axios.get(process.env.REACT_APP_DB_HOST +'/post')
      const recom = await axios.get(process.env.REACT_APP_DB_HOST +'/recommend') 
      setRowData(response.data);
      setPosts(res.data)
      setRecommend(recom.data)
    }
    getRecord()
  },[])

  // console.log("recommend:", recommend)

  function PrintPrice({value}) {
    if (value.pct_change > 0) {
      return (
          <font color="#FF303F">{(value.pct_change*100).toFixed(2)}%</font>
      )
    } else {
      return (
          <font color="#008DDD">{(value.pct_change*100).toFixed(2)}%</font>
      )
    }
  }
  const randomIndex1 = Math.floor(Math.random()*20)
  let randomIndex2 = Math.floor(Math.random()*20)
  while (randomIndex2 === randomIndex1) {
    randomIndex2 = Math.floor(Math.random()*20)
  }
  // console.log("randomIndex1 : ", randomIndex1)
  // console.log("randomIndex2 : ", randomIndex2)

  const concatUrl = (code) => "/detailstock/".concat(code)
  const extractTextPattern = /(<([^>]+)>)/gi;
  return (
    
      <Box sx={{width:'100%'}}>


          <ThemeProvider theme={TITLE_THEME}>


            <HeaderMain /> {/* 메인일때만 얘가 나오게  */}


            <Box className="searchWrap" sx={{ bgcolor: 'primary', display: 'block', justifyContent: 'center', alignItems: 'center' }}>
              <SearchBox />
              <Box className="recentSearch">
                <ul>
                  <li><a href="/detailstock/AAPL">애플</a></li>
                  <li><a href="/detailstock/MSFT">마이크로소프트</a></li>
                  <li><a href="/detailstock/LMT">록히드마틴</a></li>
                </ul>
              </Box>
            </Box>
          </ThemeProvider>



          <ThemeProvider theme={TITLE_THEME}>

            <Box className="sectionNews">
              <Box className="sectionHead">
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                분석리포트
                </Typography>
              </Box>

              { posts.slice(0,2).map((article, idx) => (
                <Link to={"/view/"+article.id}  style={{ textDecoration: 'none', color: 'black' }}>
                  <Card>
                    <CardActionArea>
                      {idx === 0 ? 
                      <CardMedia
                        component="img"
                        height="110"
                        image="/img/board/thumb_02.png" 
                        alt={article.title}
                      /> : 
                      <CardMedia
                        component="img"
                        height="110"
                        image="/img/board/thumb_01.png"
                        alt={article.title}
                      /> }
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {article.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {article.text? article.text.replace(extractTextPattern,'').substr(0,35) + "...":null}
                        </Typography>
                        <Typography className="itemDate" component="div">
                          {article.updatedAt ? article.updatedAt.substr(0,10) : null}
                            
                          </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>


                </Link>
              ))}

            </Box>


            <Box className="sectionToday">
              <ThemeProvider theme={TITLE_THEME}>
                <Box className="sectionHead">
                  <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                    <Link to="/today">
                      <MilitaryTechIcon color="primary" fontSize="large" sx={{mt:0}} />오늘의 추천주
                    </Link>
                  </Typography>

                  <IconButton
                    href="/today" // 해당 페이지로 이동 
                    size="medium"
                    edge="end"
                    aria-label="go"
                    sx={{ mx: 0, my: 0 }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              

                <Box sx={{ bgcolor: 'white', display: 'flex', justifyContent: 'center'}}>
                  <MainListedStock recommend={Array.isArray(recommend) ? recommend.filter(value => value.position==='today' && (value.index < 3)):null}/>
                </Box>
              </ThemeProvider>
            </Box>



            <Box className="sectionMarket">
              <Card variant='undefined'square>
                <CardContent>                    
                  {/* { Array.isArray(rowData) && rowData.length > 1 ? rowData.filter(item => item.position==='dow').map( (item,idx) => ( */}
                      <div>
                        <Typography variant="h6" component="div" color="text.secondary">
                          다우
                        </Typography>
                        <Typography variant="h5" > { /* Black 지정하면 되려나 했는데 안되네요~; font 코드 생성하는 부분이 삭제되었으면 합니다~ */}
                          { Array.isArray(rowData)&& rowData.length > 0 ? rowData[rowData.length - 1].dow :null}
                        </Typography>
                        <Typography>
                          { Array.isArray(rowData)&& rowData.length > 0 ?
                             rowData[rowData.length - 1].dowpct > 0 ?
                            <span className='stock-up'>{rowData[rowData.length - 1].dowpct}</span>
                            :
                            <span className='stock-down'>{rowData[rowData.length - 1].dowpct}</span>
                          :null}% 
                        </Typography>                          
                      </div>
                   
                       
                </CardContent>
              </Card>

              <Card variant="undefined" square>
                <CardContent>                    
                      <div>
                        <Typography variant="h6" component="div" color="text.secondary">
                          S&P 500
                        </Typography>
                        <Typography variant="h5" color="black">
                          { Array.isArray(rowData)&& rowData.length > 0 ? rowData[rowData.length - 1].sp500 :null}
                        </Typography>
                        <Typography>
                          { Array.isArray(rowData)&& rowData.length > 0 ? 
                          rowData[rowData.length - 1].sp500pct > 0 ?
                          <span className='stock-up'>{rowData[rowData.length - 1].sp500pct}</span>
                          :
                          <span className='stock-down'>{rowData[rowData.length - 1].sp500pct}</span>
                        :null}% 
                        </Typography>                        
                      </div>
                                  
                </CardContent>
              </Card>

              <Card variant="undefined" square>
                  <CardContent>                    
           
                        <div>
                          <Typography variant="h6" component="div" color="text.secondary">
                            나스닥
                          </Typography>
                          <Typography variant="h5" color="black">
                            { Array.isArray(rowData)&& rowData.length > 0 ? rowData[rowData.length - 1].nasdaq :null}
                          </Typography>
                          <Typography>
                          { Array.isArray(rowData)&& rowData.length > 0 ? 
                          rowData[rowData.length - 1].naspct > 0 ?
                          <span className='stock-up'>{rowData[rowData.length - 1].naspct}</span>
                          :
                          <span className='stock-down'>{rowData[rowData.length - 1].naspct}</span>
                        :null}% 
                        </Typography>                          
                        </div>
                                
                  </CardContent>
              </Card>

              <Card variant="undefined" square>
                  <CardContent>                    
                   
                        <div>
                          <Typography variant="h6" component="div" color="text.secondary">
                            러셀 2000
                          </Typography>
                          <Typography variant="h5" color="black">
                            { Array.isArray(rowData)&& rowData.length > 0 ? rowData[rowData.length - 1].russell :null}
                          </Typography>
                          <Typography>
                          { Array.isArray(rowData)&& rowData.length > 0 ? 
                          rowData[rowData.length - 1].ruspct > 0 ?
                          <span className='stock-up'>{rowData[rowData.length - 1].ruspct}</span>
                          :
                          <span className='stock-down'>{rowData[rowData.length - 1].ruspct}</span>
                        :null}% 
                        </Typography>                         
                        </div>
                                    
                  </CardContent>
                </Card>

            </Box>




            <Box className="sectionMap">
              <Box className="sectionHead">
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                  <Link to="/billboard">
                    상승/하락 지도
                  </Link>
                </Typography>

                <IconButton
                  href="/billboard" // 해당 페이지로 이동 
                  size="large"
                  edge="end"
                  aria-label="go"
                  sx={{ mx: 0, my: 0 }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>

              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItem: 'center'}}>
                <Card variant="undefined" sx={{ width: '100%'}}>
                  <AdvancerDecliner />
                </Card>
              </Box>

            </Box>

            <Box className="sectionSector">
              <Box className="sectionHead" sx={{mb:0}}>
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                  <Link to="/sectorselection/tech">
                  업종 특징주
                  </Link>
                </Typography>

                <IconButton
                  href="/sectorselection/tech" // 해당 페이지로 이동 
                  size="large"
                  edge="end"
                  aria-label="go"
                  sx={{ mx: 0, my: 0 }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Box>




            <Box className="sectionFeature">

              <Typography variant="h5" component="h4" className="sectionTitle">
                <Link to="/selection/vscore">
                  V-점수
                </Link>
              </Typography>
              <Box>
                { Array.isArray(recommend) ? recommend.filter((item, idx) => item.position === 'vscore' && (item.index===randomIndex1 | item.index===randomIndex2)).map( (item,idx) => (
                  <Box sx={{ minWidth: 100, width: '49%' }}>
                    <Card variant="undefined">
                      <CardContent>
                        <Link to={concatUrl(item.ticker)}  key={idx}>
                          <Typography variant="stockTitle" component="div">
                            <strong>{item.name_korean}</strong>
                            <small>{item.ticker}</small>
                          </Typography>
                          <Typography variant="stockPrice" component="div">
                            <span>{item.close} </span>
                            <small><PrintPrice value={item} /></small>
                          </Typography>
                          { idx % 2 === 0 ?
                          <Avatar 
                          sx={{ bgcolor: ac.deepOrange[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          > {(item.score*10).toFixed(0)}</Avatar>
                          :
                          <Avatar 
                          sx={{ bgcolor: ac.deepPurple[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          >{(item.score*10).toFixed(0)}</Avatar>
                          }
                          {
                            /*
                            Possible Color Name :
                            amber, blue, blueGrey, brown, common, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow
                            */
                          }
                        </Link>
                      </CardContent>
                    </Card>
                  </Box>
                  )
                  )
                :null}
              </Box>

              <Typography variant="h5" component="h4" className="sectionTitle">
                <Link to="/selection/divcharm">
                배당매력주
                </Link>
              </Typography>
              <Box>
                { Array.isArray(recommend) ? recommend.filter((item, idx) => item.position==='divcharm' && (item.index===randomIndex1 | item.index===randomIndex2)).map( (item,idx) => (
                  <Box sx={{ minWidth: 100, width: '49%' }}>
                    <Card variant="undefined">
                      <CardContent>
                      <Link to={concatUrl(item.ticker)}  key={idx}>
                        <Typography variant="stockTitle" component="div">
                          <strong>{item.name_korean}</strong>
                          <small>{item.ticker}</small>
                        </Typography>
                        <Typography variant="stockPrice" component="div">
                          <span>{item.close} </span>
                          <small><PrintPrice value={item} /></small>
                        </Typography>
                        { idx % 2 === 0 ?
                          <Avatar 
                          sx={{ bgcolor: ac.green[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          > {(item.score*10).toFixed(0)}</Avatar>
                          :
                          <Avatar 
                          sx={{ bgcolor: ac.red[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          >{(item.score*10).toFixed(0)}</Avatar>
                          }
                        </Link>
                      </CardContent>
                    </Card>
                  </Box>
                  )
                  )
                :null}
              </Box>


              <Typography variant="h5" component="h4" className="sectionTitle">
                <Link to="/selection/magic">
                  마법공식
                </Link>
              </Typography>
              <Box>
                { Array.isArray(recommend) ? recommend.filter((item, idx) => item.position==='magic' && (item.index===randomIndex1 | item.index===randomIndex2)).map( (item,idx) => (
                  <Box sx={{ minWidth: 100, width: '49%' }}>
                    <Card variant="undefined">
                      <CardContent>
                        <Link to={concatUrl(item.ticker)}  key={idx}>
                          <Typography variant="stockTitle" component="div">
                            <strong>{item.name_korean}</strong>
                            <small>{item.ticker}</small>
                          </Typography>
                          <Typography variant="stockPrice" component="div">
                            <span>{item.close} </span>
                            <small><PrintPrice value={item} /></small>
                          </Typography>
                          { idx % 2 === 0 ?
                          <Avatar 
                          sx={{ bgcolor: ac.brown[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          > {(item.score*10).toFixed(0)}</Avatar>
                          :
                          <Avatar 
                          sx={{ bgcolor: ac.cyan[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          >{(item.score*10).toFixed(0)}</Avatar>
                          }
                        </Link>
                      </CardContent>
                    </Card>
                  </Box>
                  )
                  )
                :null}
              </Box>

              <Typography variant="h5" component="h4" className="sectionTitle">
                <Link to="/selection/snow">
                  스노우볼
                </Link>
              </Typography>
              <Box>
                { Array.isArray(recommend) ? recommend.filter((item, idx) => item.position==='snow' && (item.index===randomIndex1 | item.index===randomIndex2)).map( (item,idx) => (
                  <Box sx={{ minWidth: 100, width: '49%' }}>
                    <Card variant="undefined">
                      <CardContent>
                      <Link to={concatUrl(item.ticker)}  key={idx}>
                        <Typography variant="stockTitle" component="div">
                          <strong>{item.name_korean}</strong>
                          <small>{item.ticker}</small>
                        </Typography>
                        <Typography variant="stockPrice" component="div">
                          <span>{item.close} </span>
                          <small><PrintPrice value={item} /></small>
                        </Typography>
                        { idx % 2 === 0 ?
                          <Avatar 
                          sx={{ bgcolor: ac.lime[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          > {(item.score*10).toFixed(0)}</Avatar>
                          :
                          <Avatar 
                          sx={{ bgcolor: ac.common[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          >{(item.score*10).toFixed(0)}</Avatar>
                          }
                        </Link>
                      </CardContent>
                    </Card>
                  </Box>
                  )
                  )
                :null}
              </Box>

              <Typography variant="h5" component="h4" className="sectionTitle">
                <Link to="/selection/fscore">
                  F-점수
                </Link>
              </Typography>
              <Box>
                { Array.isArray(recommend) ? recommend.filter((item, idx) => item.position === 'fscore' && (item.index===randomIndex1 | item.index===randomIndex2)).map( (item,idx) => (
                  <Box sx={{ minWidth: 100, width: '49%' }}>
                    <Card variant="undefined">
                      <CardContent>
                        <Link to={concatUrl(item.ticker)}  key={idx}>
                          <Typography variant="stockTitle" component="div">
                            <strong>{item.name_korean}</strong>
                            <small>{item.ticker}</small>
                          </Typography>
                          <Typography variant="stockPrice" component="div">
                            <span>{item.close} </span>
                            <small><PrintPrice value={item} /></small>
                          </Typography>
                          { idx % 2 === 0 ?
                          <Avatar 
                          sx={{ bgcolor: ac.indigo[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          > {(item.score*10).toFixed(0)}</Avatar>
                          :
                          <Avatar 
                          sx={{ bgcolor: ac.lightBlue[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          >{(item.score*10).toFixed(0)}</Avatar>
                          }
                        </Link>
                      </CardContent>
                    </Card>
                  </Box>
                  )
                  )
                :null}
              </Box>

              <Typography variant="h5" component="h4" className="sectionTitle">
                <Link to="/selection/synth">
                  합성전략
                </Link>
              </Typography>
              <Box>
                { Array.isArray(recommend) ? recommend.filter((item, idx) => item.position === 'synth' && (item.index===randomIndex1 | item.index===randomIndex2)).map( (item,idx) => (
                  <Box sx={{ minWidth: 100, width: '49%' }}>
                    <Card variant="undefined">
                      <CardContent>
                        <Link to={concatUrl(item.ticker)}  key={idx}>
                          <Typography variant="stockTitle" component="div">
                            <strong>{item.name_korean}</strong>
                            <small>{item.ticker}</small>
                          </Typography>
                          <Typography variant="stockPrice" component="div">
                            <span>{item.close} </span>
                            <small><PrintPrice value={item} /></small>
                          </Typography>
                          { idx % 2 === 0 ?
                          <Avatar 
                          sx={{ bgcolor: ac.yellow[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          > {(item.score*10).toFixed(0)}</Avatar>
                          :
                          <Avatar 
                          sx={{ bgcolor: ac.pink[200] }}
                          alt="{item.name_korean}"
                          src="이미지만들면.svg"
                          >{(item.score*10).toFixed(0)}</Avatar>
                          }
                        </Link>
                      </CardContent>
                    </Card>
                  </Box>
                  )
                  )
                :null}
              </Box>

            </Box>

            <Box className="sectionReport">
              <Box className="sectionHead">
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                  {/* <Link to="/report"> */}
                    분석리포트
                  {/* </Link> */}
                </Typography>
              </Box>
              
              <Link to={Array.isArray(posts) && posts.length>2 ? "/view/"+posts[2].id : ""} style={{ textDecoration: 'none', color: 'black' }}>
                <Card variant="undefined" sx={{display:'flex'}}>
                  <CardMedia
                      component="img"
                      height="62"
                      image="/img/board/thumb_03.png" 
                      alt="미국 배당 ETF에 투자하기 위해 알아야할 A to Z"
                  />
                  <CardContent>
                  {/* <Link to="/view/7" style={{ textDecoration: 'none', color: 'black' }}> */}
                    <Typography variant="h5" component="div">
                      { Array.isArray(posts) && posts.length>2 ? posts[2].title : null }
                      </Typography>
                      <Typography className="itemDate" component="div">
                          { Array.isArray(posts) && posts.length>2 ? posts[2].updatedAt.substr(0,10): null}
                          
                        </Typography>
                    {/* </Link> */}
                  </CardContent>
                </Card>
              </Link>

              <Link to={Array.isArray(posts) && posts.length>3 ? "/view/"+posts[3].id : ""} style={{ textDecoration: 'none', color: 'black' }}>
                <Card variant="undefined" sx={{display:'flex'}}>
                  <CardMedia
                      component="img"
                      height="62"
                      image="/img/board/thumb_04.png" 
                      alt="10년 이상 배당금 증가시킨 S&amp;P500 추천 30선"
                  />
                  <CardContent>
                  {/* <Link to="/view/7" style={{ textDecoration: 'none', color: 'black' }}> */}
                    <Typography variant="h5" component="div">
                    { Array.isArray(posts) && posts.length>3 ? posts[3].title : null }
                      </Typography>
                      <Typography className="itemDate" component="div">
                      { Array.isArray(posts) && posts.length>3 ? posts[3].updatedAt.substr(0,10): null}
                        </Typography>
                    {/* </Link> */}
                  </CardContent>
                </Card>
              </Link>

              <Link to={Array.isArray(posts) && posts.length>4 ? "/view/"+posts[4].id : ""} style={{ textDecoration: 'none', color: 'black' }}>
                <Card variant="undefined" sx={{display:'flex'}}>
                  <CardMedia
                      component="img"
                      height="62"
                      image="/img/board/thumb_05.png" 
                      alt="월세 받듯 따박따박... 월 배당주 26선"
                  />
                  <CardContent>
                  {/* <Link to="/view/7" style={{ textDecoration: 'none', color: 'black' }}> */}
                    <Typography variant="h5" component="div">
                    { Array.isArray(posts) && posts.length>4 ? posts[4].title : null }
                      </Typography>
                      <Typography className="itemDate" component="div">
                      { Array.isArray(posts) && posts.length>4 ? posts[4].updatedAt.substr(0,10): null}
                        </Typography>
                    {/* </Link> */}
                  </CardContent>
                </Card>
              </Link>

            </Box>

                {/* 아직 표출할 영상이 없어서 일단 숨겨둡니다. */}
              <Box className="sectionVideo" sx={{display:'none'}}>
                <Typography variant="h5" component="h4" className="sectionTitle">
                영상으로 보는 미국주식
                </Typography>
              </Box>

          </ThemeProvider>
      </Box>
  );
}



export default MainBody;
