import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import SearchBox from '../searchBox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TITLE_THEME from '../../customTheme.js';
import HeaderSub from '../../components/HeaderSub';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      // backgroundColor: '#2e4e79',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // // hide last border
    // '&:last-child td, &:last-child th': {
    //   border: 0,
    // },
    border: 1
  }));

function TodayRecommend() {

  const { menu } = useParams()
  const [recommend, setRowData] = useState([]);
//   const [section, setSection] = useState(menu);

  useEffect( () => {
    async function getRecord() {
      const response = await axios.get(process.env.REACT_APP_DB_HOST + '/recommend')
      setRowData(response.data);
    //   // console.log("menu : ", menu)
    }
    getRecord()
  },[])

  function PrintPrice({value}) {
    if (value.pct_change > 0) {
      return (
          <span class="stock-up">{value.close.toFixed(2)}</span>
      )
    } else {
      return (
          <span class="stock-down">{value.close.toFixed(2)}</span>
      )
    }
  }

  function IndexUpDown({value}) {
    if (value.percent > 0) {
      return (
          <span class="stock-up">{value.price}</span>
      )
    } else {
      return (
          <span class="stock-down">{value.price}</span>
      )
    }
  }
  function PrettyName(value) {
    const lim = 14
    let tt = value  //.replace(" ", "")
    if (tt && tt.length > lim) {
      tt = tt.slice(0, lim) + ".."
    } 
    return (
      // <List>
      //   <ListItem>{ tt } <br/> { value.cname }</ListItem>
      // </List>
      { tt }
    )
  }
  const menus = {
      'vscore': 'V-점수',
      'divcharm' : '배당 매력주',
      'magic' : '마법공식',
      'snow' : '스노우불',
      'fscore' : 'F-점수',
      'synth' : '합성전략'
  }
  function PrintColor({value}) {
    if (value > 0) {
      return (
        <span class="stock-up">+{value}%</span>
      )
    } else {
      return (
        <span class="stock-down">{value}%</span>
      )
    }
  }
//   const onSelect = useCallback(
//     text => {
//       setSection(text)
//     }, [menu],
//   )
  const concatUrl = (code) => "/detailstock/".concat(code)
//   const extractTextPattern = /(<([^>]+)>)/gi;
  return (
      <Box sx={{width:'100%'}}>

        <HeaderSub title="오늘의 추천" />

          <ThemeProvider theme={TITLE_THEME}>

            <Box className="sectionToday typeSub">
              <Box className="sectionHead">
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                  오늘의 추천주
                </Typography>
              </Box>          
              <Box sx={{ minWidth: 320, width: '100%' }}>
                  <img src="/img/today.png" alt="chart" width="100%"/>
              </Box>
            </Box>

            <Box className="sectionRecom typeSub">
              <Box className="sectionHead">
                <Typography variant="sectionTitle" component="h4" className="sectionTitle">
                  추천주
                </Typography>
              </Box>          
              <Box sx={{ minWidth: 320, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>
                
                
                <TableContainer component={Paper} sx={{width: '100%'}}>
                    <Table sx={{ Width: '100%' }} aria-label="customized table">
                        <TableBody>
                        {Array.isArray(recommend) && recommend.length > 0 ? recommend.filter(item => item.position==="today").map( (row, idx) => (
                            <Link to={concatUrl(row.ticker)} style={{ textDecoration: 'none' }}>
                            <StyledTableRow sx={{width:'100%'}}>     
                              <StyledTableCell className="title" align="left">
                                <Typography variant="stockTitle" component="div">
                                    <strong>{row.name_korean}</strong>
                                    <small>{row.ticker}</small>
                                  </Typography>
                              </StyledTableCell>
                              <StyledTableCell className="Price" align="right">
                                <span> {row.close} </span>
                              </StyledTableCell>
                              <StyledTableCell className="move" align="left">
                                <small><PrintColor value={(row.pct_change*100).toFixed(2)} /></small>
                              </StyledTableCell>
                                {/* <StyledTableCell className="title" align="left">

                                  <Typography variant="stockTitle" component="div">
                                    <strong>{row.name_korean}</strong>
                                    <small>{row.ticker}</small>
                                  </Typography>
                                  <Typography variant="stockPrice" component="div">
                                    <span><PrintColor value={row.close} /></span><small><PrintColor value={row.pct_change.toFixed(2)} />%</small>
                                  </Typography>


                                  <Typography variant="h5" >
                                      {row.name_korean}
                                  </Typography>
                                  <Typography variant="ticker" component="div">
                                      {row.ticker}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell className="price" align="right"><PrintColor value={row.close} /></StyledTableCell>
                                <StyledTableCell className="move" align="left"><PrintColor value={row.pct_change.toFixed(2)} />%</StyledTableCell> */}
                            </StyledTableRow>
                            </Link>
                        ))
                        :
                        null}
                        </TableBody>
                    </Table>
                </TableContainer>
              </Box>
            </Box>

            {/* 이런 종목은 어떠세요? */}

          </ThemeProvider>
      </Box>
  );
}


export default TodayRecommend;
