import './App.scss';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import MainBody from './components/mainBody';
// import HeaderMain from './components/HeaderMain';
import Nav from './components/Nav';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DetailStock from './components/detailStock';
import SearchResult from './components/searchResult';
import FineEditor from './components/fineEditor';
import FineViewer from './components/fineViewer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from './logo.svg';
import SelectionList from './components/selection/selectionList';
import ScrollToTop from './scrollToTop';
import TITLE_THEME from './customTheme.js';
import BillBoard from './components/billboard/billboard';
import SectorSelection from './components/billboard/sectorSelection';
import TodayRecommend from './components/today/todayRecommend';
import RouteChangeTracker from './components/RouteChangeTracker';
// import ReactGA from 'react-ga';
// import { useEffect } from 'react';

function App() {
  <BrowserRouter>
    RouteChangeTracker();
  </BrowserRouter>

  // const history = useNavigate();
  // useEffect(()=>{
  //   ReactGA.initialize("user id");
  //   history.listen((location) => {
  //     ReactGA.set({ page: location.pathname }); // Update the user's current page
  //     ReactGA.pageview(location.pathname); // Record a pageview for the given page
  //   });
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  // },[])

  return (
    <React.Fragment>
      <CssBaseline />      
        <Container maxWidth="sm" sx={{margin: 'auto', p:0}}>
          <ThemeProvider theme={TITLE_THEME}>

            {/* 
            
            혹시 이 자리에 메인 페이지 일떄는 
            <HeaderMain />  표출
            서브 페이지 일때는
            <HeaderSub /> 표출 가능할까요?

            지금은 그냥 코드 확인을 위해서 임의로
            메인은 maimBody.js 파일 상단에 <HeaderMain />을 넣어놓았고, 
            서브는 detailStock.js 파일 상단에 <HeaderSub />를 넣어놓았습니다.
            
            */}
            {/* GNB for main page */}

            {/* <Nav /> */}
            {/* Drawer(Sidemenu) */}
            
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

              <BrowserRouter>
                <ScrollToTop />
                <Routes>
                  <Route exact path="/" element={<MainBody />} />
                  <Route path="/list/:keyword" element={<SearchResult />} />
                  <Route path="/detailstock/:stockcode" element={<DetailStock />} />
                  <Route path="/post" element={<FineEditor />} />
                  <Route path="/view/:id" element={<FineViewer />} />
                  <Route path="/selection/:menu" element={<SelectionList />} />
                  <Route path="/billboard" element={<BillBoard />} />
                  <Route path="/sectorselection/:menu" element={<SectorSelection />} />
                  <Route path="/today" element={<TodayRecommend />} />
                </Routes>
              </BrowserRouter>
            </Box>


            <footer>
              <p class="msg">본 서비스는 (주)한국투자교육연구소의 책임하에 운영됩니다. 투자판단에 참고용으로만 사용하시길 바랍니다. 모든 투자판단은 투자자 본인의 책임으로 당사는 그 결과에 대해 법적인 책임을 지지 않습니다.</p>

              <ul class="serivceLinks">
                <li><a href="#">이용약관</a></li>
                <li><a href="#">회사소개</a></li>
              </ul>

              <p class="company">(주)한국투자교육연구소</p>
              <ul class="companyInfo">
                <li>서울시 영등포구 선유로9가 10, SK V1 센터 1001호</li>
                <li>CS : <a href="mailto:cs@itooza.com">cs@itooza.com</a></li>
                <li>사업자등록번호 : 101-86-35072</li>
                <li>대표 : 김재영</li>
              </ul>
                
            </footer>

          </ThemeProvider>
        </Container>
    </React.Fragment>
  );
}


export default App;


