import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';

import DetailEPS from './performanceYear/detailEPS';
import DetailRevenue from './performanceYear/detailRevenue';
import DetailEbit from './performanceYear/detailEbit';
import DetailNetincome from './performanceYear/detailNetincome';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import TITLE_THEME from '../../customTheme.js';

import Radio from '@mui/material/Radio';

function PerformanceYear({onSelect}) {

  const toApp = useCallback(
    text => {
        onSelect(text)
        // e.preventDefault()
    }, [onSelect]
  )
  const [funData, setFunData] = useState([]);
  const { stockcode } = useParams()

  useEffect( () => {
    async function getRecord() {

    if (typeof stockcode !== 'undefined') {
      const res = await axios.post(process.env.REACT_APP_DB_HOST +'/perfyear', { stockcode })
      // console.log("Perfyear fetching Fundamental data..")
      setFunData(res.data)
      // // console.log("fundata[4]: ", funData[4])
      // // console.log("fundata[0]: ", funData[0])

    } 
  }
  getRecord()
  },[])

  return (
    <Stack spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <ThemeProvider theme={TITLE_THEME}>
            
            <Box sx={{ minWidth: 320, display: 'flex', justifyContent:'flex-end' }} className="radioSection">
              <label><Radio name="period" size='small' onClick={() => toApp("perfqter")} /> 분기</label>
              <label><Radio name="period" size='small' checked /> 연간</label>
            </Box>


            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num1">
              <Typography component="h5" variant="title" className="stockTitle">
                  EPS
              </Typography>

              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                <CardContent>
                  <Typography variant="none" component="h6">
                    최근 EPS
                  </Typography>
                  <Typography variant="none" component="p">
                    {/* $2.37 */}
                    { funData.map( (item, idx) => (
                      <div> { idx===0 ? item.epsdil ? '$' + item.epsdil.toFixed(2) : null : null} </div>
                    ))}
                  </Typography>
                </CardContent>
              </Card>
              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                <CardContent>
                  <Typography variant="none" component="h6">
                    YoY
                  </Typography>
                  <Typography variant="none" component="p">
                    {/* +35.01% */}
                    { funData.map( (item, idx) => (
                      <div> { idx===0 ? ( (item.epsdil - funData[idx+1].epsdil) / funData[idx+1].epsdil * 100).toFixed(2) + '%': null} </div>
                    ))}
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <DetailEPS value={funData} />
              </Card>
            </Box>   
            {/* 차트색상 변경해야함  */}


            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num2">
              <Typography component="h5" variant="title" className="stockTitle">
                  매출액
              </Typography>
             
              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                <CardContent>
                  <Typography variant="none" component="h6">
                    최근 매출액
                  </Typography>
                  <Typography variant="none" component="p">
                    { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.revenue ? '$' + (item.revenue / 1000000) + ' M' : null : null} </div>
                      ))}
                  </Typography>
                </CardContent>
              </Card>
            
              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                <CardContent>
                  <Typography variant="none" component="h6">
                    YoY
                  </Typography>
                  <Typography variant="none" component="p">
                    { funData.map( (item, idx) => (
                        <div> { idx===0 ? ((item.revenue - funData[idx+1].revenue) / funData[idx+1].revenue * 100).toFixed(2) + '%': null} </div>
                      ))}
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <DetailRevenue value={funData} />
              </Card>
            </Box>   


            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num3">
              <Typography component="h5" variant="title" className="stockTitle">
                영업이익
              </Typography>
              
              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                <CardContent>
                  <Typography variant="none" component="h6">
                    최근 영업이익
                  </Typography>
                  <Typography variant="none" component="p">
                    { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.opinc ? '$' + (item.opinc / 1000000) + " M" : null : null} </div>
                      ))}
                  </Typography>
                </CardContent>
              </Card>

              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                <CardContent>
                  <Typography variant="none" component="h6">
                    YoY
                  </Typography>
                  <Typography variant="none" component="p">
                    { funData.map( (item, idx) => (
                        <div> { idx===0 ? ((item.opinc - funData[idx+1].opinc) / funData[idx+1].opinc * 100).toFixed(2) + '%': null} </div>
                      ))}
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
              <Card variant="undefined" square>
                <DetailEbit value={funData} />
              </Card>
            </Box>   




            <Box sx={{ minWidth: 320, display: 'flex' }} className="detail num4">
              <Typography component="h5" variant="title" className="stockTitle">
                순이익
              </Typography>
              
              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                <CardContent>
                  <Typography variant="none" component="h6">
                    최근 순이익
                  </Typography>
                  <Typography variant="none" component="p">
                    { funData.map( (item, idx) => (
                        <div> { idx===0 ? item.netinc ? '$' + (item.netinc / 1000000) + " M" : null : null} </div>
                      ))}
                  </Typography>
                </CardContent>
              </Card>

              <Card variant="undefined" square sx={{ minWidth: 100 }}>
                <CardContent>
                  <Typography variant="none" component="h6">
                    YoY
                  </Typography>
                  <Typography variant="none" component="p">
                    { funData.map( (item, idx) => (
                        <div> { idx===0 ? ((item.netinc - funData[idx+1].netinc) / funData[idx+1].netinc * 100).toFixed(2) + '%': null} </div>
                      ))}
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ minWidth: 320, width: '100%' }} className="chart">
            <Card variant="undefined" square>
                <DetailNetincome value={funData} />
              </Card>
            </Box>   



          </ThemeProvider>
    </Stack>
  );
}


export default PerformanceYear;
