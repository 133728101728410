import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import TextField from '@mui/material/TextField';
import { TouchApp } from '@mui/icons-material';
import { useCallback } from 'react';

export default function SearchBox({onSelect}) {

  const onSubmit = e => {
    e.preventDefault()
    // console.log(e)
    // console.log(e.target[1].value)
    window.location.href = "/list/" + e.target[1].value
    // window.location.href = "https://itooza.com"  + e.target[1].value
    }
  
  const toApp = useCallback(
    e => {
      onSelect(false)
        e.preventDefault()
    }, [onSelect]
    )

  return (
    <Paper
      component="div"
      elevation={0}
      variant="outlined"
      sx={{ p: '4px 4px', display: 'flex', alignItems: 'center' }}
    >
      <form onSubmit={onSubmit} style={{display: 'flex', width: '100%'}} onClick={(e) => toApp(e)} >
      
      <IconButton
         // size="xlarge"
          // edge="start"
          // color="primary"
          // aria-label="search"
          // sx={{ mr: 0, ml: 1, fontSize: 60 }}
        >
          <SearchIcon sx={{ fontSize: 30 }} color="primary" />
        </IconButton>

        
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          name="search"
          placeholder="어떤 종목이 궁금하세요?"
          inputProps={{ 'aria-label': 'search theme', style: { fontSize: 16 } }}
          onClick={(e) => toApp(e)}
        />
        {/* <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton> */}
      
      </form>
    </Paper>





  );

}


