import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

// 테마 등 공통 설정을 여기서 관리합니다.

    const TITLE_THEME = createTheme({
        palette: {
          primary: { // 피그마에 정의된 녹색
            light: '#BDE1A0',
            main: '#82CA4A',
            dark: '#45900A',
            contrastText: '#fff',
          },
          secondary: { // 아직 정하지 않음
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
          },
        },
        typography: {
          "fontFamily": '"Spoqa Han Sans Neo", "Noto Sans KR", "Nanum Gothic", "MalgunGothic", Dotum, Lato, Arial, sans-serif;',
          "fontSize": 15,
          "fontWeightLight": 300,
          "fontWeightRegular": 400,
          "fontWeightMedium": 500
         }
      });    


export default TITLE_THEME;