import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ApexCharts from 'react-apexcharts';


export default function DetailStockRadar({fun}) {
        // const result=[]
        // chartData.forEach( values => result.push({ "x": values.date.substr(5,5), "y": values.close.toFixed(1) }))

        let title = "V-점수"
        let profitability=1
        let growth=1
        let stability=1
        let momentum=1
        let valuation=1
        let vscore = 1
        if(Array.isArray(fun)&&fun.length >0){
            profitability=fun[0].profitability
            growth=fun[0].growth
            stability=fun[0].stability
            momentum=fun[0].momentum
            valuation=fun[0].valuation
            vscore=fun[0].vscore //Math.round((profitability+growth+stability+momentum+valuation)/5)
            title = "V-점수 : " + vscore.toString() + "/10"
        } 
        
        // console.log(title)
        const data = {
        
          series: [{
              name: 'Vscore',
              data: [profitability, growth, stability, momentum, valuation]
            }],
            options: {
              chart: {
                type: 'radar',
                height: 300,
                toolbar: {
                    show:false,
                },
                animations: {
                  enabled: false,
                },
                dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1
                  },
                parentHeightOffset: 0,
                offsetY: 0
              },
              colors: ['#2E93fA', '#3deb94', '#eb3d82'],
              title: {
                text: title,
                align: 'center'
              },
              dataLabels: {
                enabled: true,
                background: {
                    enabled: true,
                    borderRadius:2,
                }
            },
              stroke: {
                width: 2,    
              },
              markers: {
                  size:0
              },
              xaxis: {
                  categories: ['수익성','성장성','안정성','모멘텀','밸류에이션']
              },
              yaxis: {
                min: 0,
                max: 10,
                tickAmount: 5
              }         
            },
          
      }      

        return (
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="radar" width={'100%'} height={'350px'}/>
            </div>
        );
    
}
