import * as React from 'react';
import ApexCharts from 'react-apexcharts';
import { useState, useEffect } from 'react';

export default function MarketIndexChart({value, section}) {

  const [result, setResult] = useState([])
  // const [yoy, setyoy] = useState([])
  const [days, setDays] = useState([])
  const IndexName = {
    'dow': '다우',
    'sp500': 'S&P 500',
    'nasdaq' : '나스닥',
    'russell' : '러셀 2000'
  }
  useEffect( () => {
    function getRecord() {
        let result = [];
        let days = [];
        let res = 0;
        if(Array.isArray(value) && value.length > 0) {
            value.forEach( values => {
                if(section==='dow'){
                    res = values.dow
                } else if(section==='sp500'){
                    res = values.sp500
                } else if(section==='nasdaq'){
                    res = values.nasdaq
                } else if(section==='russell'){
                    res = values.russell
                }
                result.push({x:values.date.toString().substr(0,10), y:res.toFixed(1)})
                days.push(values.date.toString().substr(0,10))
            })
        }
        // // console.log("MarketIndexChart result: ", result)
        // // console.log("MarketIndexChart days: ", days)
        setResult(result)
        setDays(days)
      }
      getRecord()
      }, [value, section])

        let data = {
    
            series: [{
                name: 'DOW',
                type: 'line',
                data: result
              } 
              // {
              //   name: 'YoY(%)',
              //   type: 'line',
              //   data: yoy
              // }
            ],
              options: {
                chart: {
                  height: 350,
                  fontFamily: "Noto Sans KR",
                  type: 'line',
                  animations: {
                    enabled: false,
                  },
                  toolbar: {
                      show: false,
                  }
                },
                stroke: {
                  width: [2]
                },
                colors: ['#82CA4A','#9AB3C1' ],
                title: {
                  text: IndexName[section]
                },
                legend: {
                  position: "top"
                },
                dataLabels: {
                  enabled: false,
                  // enabledOnSeries: [1]
                },
                labels: days,
                xaxis: {
                  type: 'datetime',
                  categories: days,
                  tickAmount: 50,
                },
                yaxis: [{
                  // title: {
                  //   text: 'EPS(금액)',
                  // },
                
                }, 
                // {
                //   opposite: true,
                // }
              ]
              },
          
      }      

        return (
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="line" width={'100%'} height={'380px'}/>
            </div>
        );
    
}
