import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ApexCharts from 'react-apexcharts';


export default function DetailStockChart({chartData}) {
        // const [rowData, setRowData] = useState([]);
        // // console.log(stockcode.value)

        // useEffect( () => {
        //   async function getRecord() {
        //       const response = await axios.get(process.env.REACT_APP_DB_HOST +'/dailychart/' + stockcode.value)
        //       // console.log(response.data);
        //       setRowData(response.data);
        //   }
        //   getRecord()
        // },[])
        const result=[]
        chartData.forEach( values => result.push({ "x": values.date.substr(5,5), "y": values.close.toFixed(1) }))

        const data = {
    
          series: [{
              // name: Array.isArray(chartData)&& chartData.length > 0 ? chartData[0].Basic.ticker : 'stock',
              name: '주가',
              data: result,
            }],
            options: {
              chart: {
                type: 'area',
                height: 350,
                toolbar: false,
                animations: {
                  enabled: false,
                },
                parentHeightOffset: 0,
                offsetY: 10
              },
              
              title: {
                // text: '일일 차트',
                align: 'left'
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                width: 1,
                dashArray: 0,      
            },
              xaxis: {
                
                // type: 'datetime',
                tickAmount: 7,
                labels: {
                  show: false              
                }
              },
         
            },
          
      }      

        return (
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="area" width={'100%'} height={'350px'}/>
            </div>
        );
    
}
