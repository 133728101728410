import * as React from 'react';
import ApexCharts from 'react-apexcharts';
import { useState, useEffect } from 'react';

export default function SectormapChart({rowData, period}) {


  // console.log("SectormapChart, section: ", period)
  // console.log("SectormapChart, rowData: ", rowData)

  const menusToName = {
    'Financial Services' :'금융',
    'Real Estate' : '부동산',
    'Technology': '정보기술',
    'Energy': '에너지',
    'Consumer Defensive':'필수소비재',
    'Communication Services':'커뮤니케이션 서비스',
    'Industrials':'산업재',
    'Healthcare':'헬스케어',
    'Consumer Cyclical' : '임의소비재',
    'Utilities':'유틸리티',
    'Basic Materials' : '소재'
}

    let plussector = []
    let minussector = []
    let yb = []
    if(Array.isArray(rowData) && rowData.length > 0) {
        rowData.forEach( values => {
            if(values.dist===period && values.sector){
                if(values.pct_change > 0){
                    plussector.push({x:menusToName[values.sector], y:values.pct_change?(values.pct_change*100).toFixed(2):null})
                    minussector.push({x:menusToName[values.sector], y:null})
                    yb.push(menusToName[values.sector])
                } else {
                    plussector.push({x:menusToName[values.sector], y:null})
                    minussector.push({x:menusToName[values.sector], y:values.pct_change?(values.pct_change*100).toFixed(2):null})
                    yb.push(menusToName[values.sector])
                }
            }
        })
    }


    // console.log("SectormapChart, plussector: ", plussector )
    // console.log("SectormapChart, minussector: ", minussector )

    let data = {

        series: [{
            name: '상승섹터',
            data: plussector
            }, 
            {
            name: '하락섹터',
            data: minussector
            }
        ],
            options: {
            chart: {
                height: 350,
                type: 'bar',
                stacked: true,
                animations: {
                enabled: false,
                },
                toolbar: {
                    show: false,
                }
            },
            colors: ['#FF5C00', '#4881D6'],
            stroke: {
                width: 1,
                colors: ["#f7db8d"]
                },
            // title: {
            //     text: period + ' 섹터 지도'
            // },
            legend: {
                show: false,
                position: "top"
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '90%',
                    dataLabels: {
                        position: 'bottom',
                        // maxitems: 100,
                        hideOverflowingLabels: false,
                        // total: {
                        //     enabled: false,
                        //     style: {
                        //         color: '#333333',
                        //         fontSize: '12px',
                        //         fontWeight: 600
                        //     }
                        // }
                        
                    }
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                offsetX: 2,
                style: {
                    colors: ['#333']
                  },
                formatter: function (val, opts) {
                    return val ? val + '%': ''
                },
            },

            grid: {
                xaxis: {
                    lines: {
                    show: false
                    }
                }
                },
            xaxis: {
                categories: yb,
            },
            yaxis: [{
                // title: {
                //   text: 'EPS(금액)',
                // },
            
            }, 
            // {
            //   opposite: true,
            // }
            ]
            },
        
    }      

    return (
        <div id="chart">
            <ApexCharts options={data.options} series={data.series} type="bar" width={'100%'} height={'350px'}/>
        </div>
    );
    
}
