
import { Viewer } from '@toast-ui/react-editor'
import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';
// import { Typography } from '@mui/material';
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import TITLE_THEME from '../customTheme.js';
import HeaderSub from '../components/HeaderSub';

function FineViewer() {
  const { id } = useParams()
  const [content, setContent] = useState("")

  useEffect( () => {
    async function getRecord() {

      if (typeof id !== 'undefined') {
        const response = await axios.post(process.env.REACT_APP_DB_HOST +'/view', { id })
        // // console.log(response.data);
        setContent(response.data);
      } else {
        const response = await axios.get(process.env.REACT_APP_DB_HOST +'/view')
        // // console.log(response.data);
        setContent(response.data);
      }
    }
    getRecord()
  },[])



  return <Box sx={{ minWidth: 100, width: '100%' }} className="boardBasic">
            

            <HeaderSub title="리포트" /> {/* 서브 공통 메뉴  */}


            {content ? 
                <Box sx={{ minWidth: 100, width: '100%' }} className="boardTitle">
                  <Typography variant="category" component="div">
                    카테고리
                  </Typography>
                  <Typography variant="date" component="div">
                    {content[0].updatedAt.substr(0,10) }
                  </Typography>
                  <Typography variant="h5" component="h3">
                    {content[0].title} 
                  </Typography>
                </Box> 
            : null}


              <Box className="boardContent">
                  {/* <Typography variant="h4" color="black"> */}
                    
                      {content ?  <Viewer initialValue={content[0].text } />:null}
                  
                  {/* </Typography> */}
              </Box>

              <Box>
                
              </Box>
        </Box>;
}



export default FineViewer;
