import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ContactSupportOutlined } from '@mui/icons-material';
import { Link } from "react-router-dom";
import { Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    // backgroundColor: '#2e4e79',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
  border: 1
}));

function Chapters({ value }) {
    const chapters = value.split("\n");
  
    return (
      <List>
        {chapters.map((chapter, i) => (
          <ListItem sx={{ m:0, p:0 }} key={i}>{chapter}</ListItem>
        ))}
      </List>
    );
  }

  function PrettyName({value}) {
    const lim = 14
    let tt = value  //.replace(" ", "")
    if (tt.length > lim) {
      tt = tt.slice(0, lim) + ".."
    } 
    return (
      // <List>
      //   <ListItem>{ tt } <br/> { value.cname }</ListItem>
      // </List>
      <div>
      { tt }
      </div>
    )
  }

function PrintColor({value}) {
  if (value > 0) {
    return (
      <span class="stock-up">+{value}</span>
    )
  } else {
    return (
      <span class="stock-down">{value}</span>
    )
  }
}

export default function MainListedStock({recommend}) {

  // const [rowData, setRowData] = useState([]);

  // useEffect( () => {
  //   async function getRecord() {
  //     const response = await axios.get(process.env.REACT_APP_DB_HOST +'/market10')
  //     var data = response.data;
  //     // console.log(data);
  //     setRowData(data);
  //   }
  //   getRecord()
  // },[])

  const concatUrl = (code) => "/detailstock/".concat(code)

  return (
    <TableContainer component={Paper} sx={{width: '100%'}}>
      <Table sx={{ Width: '100%' }} aria-label="customized table">
        {/* <TableHead>
          <TableRow >
            <StyledTableCell align="center" sx={{m:0, pt:1, pb:1, width:'140px'}}>종목명</StyledTableCell>
            <StyledTableCell align="center" sx={{m:0, pt:1, pb:1}}>티커</StyledTableCell>
            <StyledTableCell align="center" sx={{m:0, pt:1, pb:1}}>가격</StyledTableCell>
            <StyledTableCell align="left" sx={{m:0, pt:1, pb:1, display: 'flex', justifyContent: 'right'}}>등락(%)</StyledTableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          
          {recommend ? recommend.map((row) => (
            <Link to={concatUrl(row.ticker)} style={{ textDecoration: 'none' }}>
              <StyledTableRow key={row.ticker} sx={{width:'100%'}}>     
                <StyledTableCell className="title" align="left">
                  <Typography variant="stockTitle" component="div">
                      <strong><PrettyName value={row.name_korean} /></strong>
                      <small>{row.ticker}</small>
                    </Typography>
                </StyledTableCell>
                {/* <StyledTableCell className="price" align="right"><PrintColor value={row.close} /></StyledTableCell> */}
                <StyledTableCell className="price" align="right">{row.close} </StyledTableCell>
                <StyledTableCell className="move" align="left"><PrintColor value={(row.pct_change*100).toFixed(2)} />%</StyledTableCell>
              </StyledTableRow>
            </Link>
          ))
          :
          null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}