import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import SearchBox from '../searchBox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import HeaderSub from '../../components/HeaderSub';
import TITLE_THEME from '../../customTheme.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import * as ac from '@mui/material/colors'; 

function SelectionList() {

  const { menu } = useParams()
  const [recommend, setRowData] = useState([]);
//   const [section, setSection] = useState(menu);

  useEffect( () => {
    async function getRecord() {
      const response = await axios.get(process.env.REACT_APP_DB_HOST + '/selection')
      setRowData(response.data);
    //   // console.log("menu : ", menu)
    }
    getRecord()
  },[menu])

  function PrintPrice({value}) {
    if (value.pct_change > 0) {
      return (
        <span class="stock-up">{(value.pct_change*100).toFixed(2)}<small>%</small></span>
      )
    } else {
      return (
        <span class="stock-down">{(value.pct_change*100).toFixed(2)}<small>%</small></span>
      )
    }
  }

  function IndexUpDown({value}) {
    if (value.percent > 0) {
      return (
        <span class="stock-up">{value.price}</span>
      )
    } else {
      return (
        <span class="stock-down">{value.price}</span>
      )
    }
  }
  function PrettyName(value) {
    const lim = 14
    let tt = value  //.replace(" ", "")
    if (tt && tt.length > lim) {
      tt = tt.slice(0, lim) + ".."
    } 
    return (
      // <List>
      //   <ListItem>{ tt } <br/> { value.cname }</ListItem>
      // </List>
      <div>
      { tt }
      </div>
    )
  }
  const menus = { // 기존탭
      'vscore': 'V-점수',
      'divcharm' : '배당 매력주',
      'magic' : '마법공식',
      'snow' : '스노우볼',
      'fscore' : 'F-점수',
      'synth' : '합성전략'
  }
  const menuToValue = {
    'vscore' : 0,
    'divcharm' : 1,
    'magic' : 2,
    'snow' : 3,
    'fscore' : 4,
    'synth' : 5
  }
  const valueToMenu = {
    0: 'vscore', 
    1: 'divcharm',
    2: 'magic',
    3: 'snow',
    4: 'fscore',
    5: 'synth'
  }
//   const onSelect = useCallback(
//     text => {
//       setSection(text)
//     }, [menu],
//   )
  const concatUrl = (code) => "/detailstock/".concat(code)
//   const extractTextPattern = /(<([^>]+)>)/gi;

// https://mui.com/material-ui/react-tabs/#nav-tabs
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

  const [value, setValue] = React.useState(menuToValue[menu]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.location.href = "/selection/" + valueToMenu[newValue]
  };

  const pallete = {
    1: ac.deepOrange[200],
    2: ac.deepPurple[200],
    3: ac.green[200],
    4: ac.red[200],
    5: ac.brown[200],
    6: ac.cyan[200],
    7: ac.lime[200],
    8: ac.common[200],
    9: ac.indigo[200],
    10: ac.lightBlue[200],
    11: ac.yellow[200],
    12: ac.pink[200]
  }
  

  return (
      <Box sx={{width:'100%'}} className="miningList">

        <HeaderSub title="워치리스트" />


          <ThemeProvider theme={TITLE_THEME}>

          
            {/* <Box sx={{ minWidth: 320}} className="themeTitle">
              <Typography variant="h5" component="h2">
                발굴 스타일
              </Typography>
            </Box> */}

            <Box sx={{ width:'100%'/*minWidth:320, maxWidth: { xs: 320, sm: 480 }*/ }} className="tabWrapAuto">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                //scrollButtons="auto" // Auto로 하면 제대로 나오지 않아서 일단 강제로 함
                scrollButtons
                allowScrollButtonsMobile
                selectionFollowsFocus
                aria-label="scrollable auto tabs"
              >
                <Tab label="V-점수"  />
                <Tab label="배당 매력주"  />
                <Tab label="마법공식"  />
                <Tab label="스노우볼"  />
                <Tab label="F-점수"  />
                <Tab label="합성전략" />
              </Tabs>
            </Box>
            {/* 탭 메뉴 링크에 따라 활성화 되는 부분은 어떻게 하는지 모르겠습니다! */}


            <Box  className="tabContentWrap" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap:'wrap' }}>

              { Array.isArray(recommend) && recommend.length > 0 ? recommend.filter(item => item.position===menu).map( (item,idx) => (
                  <Box sx={{ width:'50%' }} className="tabContentItem">
                    
                        <Card variant="undefined">
                            <CardContent>
                              <Link to={concatUrl(item.ticker)} style={{ textDecoration: 'none', color: '$black-900' }}>

                                <Typography variant="stockTitle" component="div">
                                  <strong>{item.name_korean}</strong>
                                  <small>{item.ticker}</small>
                                </Typography>
                                <Typography variant="stockPrice" component="div">
                                  {/* <span><PrintPrice value={item} /></span> */}
                                  <span class="body"> {item.close.toFixed(2)} </span>
                                  {/* <small>{item.pct_change.toFixed(2)}%</small> */}
                                  <small><PrintPrice value={item} /></small>
                                </Typography>

                                <Avatar 
                                  // sx={{ bgcolor: ac.deepOrange[200] }}
                                  sx={{ bgcolor: pallete[Math.floor(Math.random()*12)+1] }}
                                  alt="{item.name_korean}"
                                  src="이미지만들면.svg"
                                  > {(item.score*10).toFixed(0)}
                                </Avatar>
                              </Link>
                            </CardContent>
                        </Card>
                     
                  </Box>
            
                    )
                )
              :null}

            </Box>



          </ThemeProvider>
      </Box>
  );
}


export default SelectionList;